<template>
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1 style="color:red;text-align:center">{{ $t("error_msg") }}</h1>
        </div>
        <!-- ./col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      load: false,
      dashboard: {}
    };
  },
  created() {},
  methods: {}
};
</script>
