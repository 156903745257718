<template>
  <div>
    <b-modal
      ref="edit_discount_modal"
      :title="$t('edit') + ' ' + $t('discount_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            variant="danger"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.name_ar }"
                v-model="edit_data.name_ar"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.name_ar || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.name_en }"
                v-model="edit_data.name_en"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.name_en || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("discount_type") }}</label>
              <select
                class="form-control custom-select"
                :class="{
                  'is-invalid': edit_errors.discount_type
                }"
                v-model="edit_data.discount_type"
              >
                <option value="percentage">{{ $t("percentage") }}</option>
                <option value="fixed">{{ $t("fixed_value") }}</option>
              </select>
              <span class="error invalid-feedback">{{
                (edit_errors.discount_type || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("discount_value") }}</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.discount_value
                }"
                v-model="edit_data.discount_value"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.discount_value || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("discount_start") }}</label>
              <input
                type="datetime-local"
                class="form-control"
                :class="{
                  'is-invalid': edit_errors.start_date
                }"
                v-model="edit_data.start_date"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.start_date || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("discount_end") }}</label>
              <input
                type="datetime-local"
                class="form-control"
                :class="{
                  'is-invalid': edit_errors.end_date
                }"
                v-model="edit_data.end_date"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.end_date || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("categories") }}</label>
              <multiselect
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                v-model="edit_data.categories_ids"
                :placeholder="$t('search_select') + ' ' + $t('categories')"
                :multiple="true"
                :clear-on-select="false"
                :close-on-select="false"
                :options-limit="300"
                :show-no-results="false"
                :hide-selected="true"
                :options="categories"
                track-by="id"
                :label="'name_' + $i18n.locale"
              >
              </multiselect>
              <span class="error invalid-feedback d-block">{{
                (edit_errors.categories_ids || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateDiscount"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("discounts") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("discounts") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(discounts, 'id')">
                              #
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(discounts, 'name_'.$i18n.local)"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(discounts, 'discount_value')"
                            >
                              {{ $t("discount") }}
                            </th>
                            <th>
                              {{ $t("categories") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(discounts, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="6"
                              class="no_data"
                              v-if="load && discounts.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in discounts"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>

                            <td>{{ item["name_" + $i18n.locale] }}</td>
                            <td>
                              {{ item.discount_value
                              }}{{
                                item.discount_type == "percentage"
                                  ? "%"
                                  : " " + $t("sar")
                              }}
                            </td>
                            <td>
                              {{
                                item.categories
                                  .map(e => e["name_" + $i18n.locale])
                                  .join(",")
                              }}
                            </td>
                            <td>
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit discounts')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditDiscount(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete discounts')"
                                @click="deleteDiscount(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add discounts')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                  {{ $t("discount_2") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.name_ar }"
                              v-model="add_data.name_ar"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name_ar || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.name_en }"
                              v-model="add_data.name_en"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name_en || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_type") }}</label>
                            <select
                              class="form-control custom-select"
                              :class="{
                                'is-invalid': add_errors.discount_type
                              }"
                              v-model="add_data.discount_type"
                            >
                              <option value="percentage">{{
                                $t("percentage")
                              }}</option>
                              <option value="fixed">{{
                                $t("fixed_value")
                              }}</option>
                            </select>
                            <span class="error invalid-feedback">{{
                              (add_errors.discount_type || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_value") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.discount_value
                              }"
                              v-model="add_data.discount_value"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.discount_value || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_start") }}</label>
                            <input
                              type="datetime-local"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.start_date
                              }"
                              v-model="add_data.start_date"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.start_date || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_end") }}</label>
                            <input
                              type="datetime-local"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.end_date
                              }"
                              v-model="add_data.end_date"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.end_date || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("categories") }}</label>
                            <multiselect
                              selectLabel=""
                              selectedLabel=""
                              deselectLabel=""
                              v-model="add_data.categories_ids"
                              :placeholder="
                                $t('search_select') + ' ' + $t('categories')
                              "
                              :multiple="true"
                              :clear-on-select="false"
                              :close-on-select="false"
                              :options-limit="300"
                              :show-no-results="false"
                              :hide-selected="true"
                              :options="categories"
                              track-by="id"
                              :label="'name_' + $i18n.locale"
                            >
                            </multiselect>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.products_ids || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addDiscount"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Discounts",
  data() {
    return {
      load: false,
      discounts: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        discount_type: "percentage"
      },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      load_results: false,
      add_results: [],
      edit_results: [],
      categories: []
    };
  },
  components: {
    Multiselect
  },
  created() {
    this.getDiscounts();
  },
  watch: {
    currentPage() {
      this.getDiscounts();
    }
  },
  methods: {
    getDiscounts() {
      return this.axios
        .get("discounts", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.discounts = res.result.discounts.data;
            this.categories = res.result.categories;
            this.rows = res.result.discounts.total;
            this.perPage = res.result.discounts.per_page;
          }
        })
        .catch(error => {
          this.handleError("getDiscounts", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getDiscounts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addDiscount() {
      this.add_disabled = true;
      this.add_error = this.add_success = "";
      this.add_errors = {};
      this.axios
        .post("discounts/add", {
          ...this.add_data,
          categories_ids: (this.add_data.categories_ids || []).map(e => e.id)
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getDiscounts();
            this.add_data = {};
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addDiscount", res.message);
          }
        })
        .catch(error => {
          this.handleError("addDiscount", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateDiscount() {
      this.edit_disabled = true;
      let fd = new FormData();
      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.edit_errors = {};
      this.axios
        .post("discounts/update", {
          ...this.edit_data,
          categories_ids: (this.edit_data.categories_ids || []).map(e => e.id)
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.edit_data = { ...res.result.discount };
            this.edit_data.start_date = this.$moment(
              this.edit_data.start_date
            ).format("YYYY-MM-DDThh:mm");
            this.edit_data.end_date = this.$moment(
              this.edit_data.end_date
            ).format("YYYY-MM-DDThh:mm");
            this.$set(
              this.edit_data,
              "categories_ids",
              this.edit_data.categories
            );
            let i = this.discounts.findIndex(e => e.id == this.edit_data.id);
            this.$set(this.discounts, i, res.result.discount);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateDiscount", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateDiscount", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    deleteDiscount(id) {
      let index = this.findIndex(this.discounts, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("discounts/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.discounts.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteDiscount", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteDiscount", error);
              });
          }
        });
    },
    showEditDiscount(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.discounts.find(e => e.id == id) };
      this.edit_data.start_date = this.$moment(
        this.edit_data.start_date
      ).format("YYYY-MM-DDThh:mm");
      this.edit_data.end_date = this.$moment(this.edit_data.end_date).format(
        "YYYY-MM-DDThh:mm"
      );
      this.$set(this.edit_data, "categories_ids", this.edit_data.categories);
      this.$refs.edit_discount_modal.show();
    }
  }
};
</script>
