import axios from "axios";
export default function can({ next, store, to }) {
  if (
    store.state.user_type == "advertiser" ||
    store.state.user_type == "user" ||
    !store.state.access_token
  ) {
    return next();
  } else if (store.state.user_type == "admin" && store.state.access_token) {
    axios
      .get("profile/permissions")
      .then(response => {
        store.commit("setPermissions", response.data.result.permissions);
      })
      .finally(() => {
        let permission = to.meta.permission;
        if (store.state.permissions.indexOf("show " + permission) !== -1) {
          return next();
        } else {
          return next({
            name: "Error"
          });
        }
      });
  } else {
    return next({
      name: "Login"
    });
  }
}
