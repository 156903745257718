<template>
  <div>
    <b-modal
      ref="edit_attribute_modal"
      :title="$t('edit') + ' ' + $t('attribute_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            variant="danger"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.name_ar"
              />
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                v-model="edit_data.name_en"
              />
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("attribute_set") }}</label>
              <select
                class="form-control custom-select"
                v-model="edit_data.attribute_set_id"
              >
                <option selected="" disabled="" value="">{{
                  $t("select")
                }}</option>
                <option
                  v-for="(set, i) in attribute_sets"
                  :key="i"
                  :value="set.id"
                  >{{ set["name_" + $i18n.locale] }}</option
                >
              </select>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("categories") }}</label>
              <multiselect
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                v-model="edit_data.categories"
                :placeholder="$t('select') + ' ' + $t('categories')"
                :multiple="true"
                :options="categories.map(e => e.id)"
                :custom-label="
                  opt =>
                    categories.find(x => x.id == opt)[
                      'cat_name_' + $i18n.locale
                    ]
                "
              ></multiselect>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("type") }}</label>
              <select
                class="form-control custom-select"
                v-model="edit_data.attr_type"
              >
                <option selected="" disabled="" value="">{{
                  $t("select")
                }}</option>
                <option
                  v-for="(type, i) in attr_types"
                  :key="i"
                  :value="type"
                  >{{ $t(type) }}</option
                >
              </select>
            </div>
            <div v-if="edit_data.attr_type == 'options'">
              {{ $t("options_2") }}
              <button
                type="button"
                @click.prevent="addOption"
                class="btn btn-primary btn-sm mr-2 ml-2"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div v-if="edit_data.attr_type == 'options'">
              <div
                class="input-group mt-2"
                v-for="(value, i) in options"
                :key="i"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="">{{ i + 1 }}</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="options[i].value_ar"
                />
                <input
                  type="text"
                  class="form-control"
                  v-model="options[i].value_en"
                />

                <div class="input-group-append">
                  <button
                    class="btn btn-success"
                    type="button"
                    @click.prevent="deleteOption(i)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateAttribute"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("attributes") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("attributes") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th
                              class="sort"
                              @click="kimoSort(attributes, 'id')"
                            >
                              #
                            </th>
                            <th
                              class="sort"
                              @click="
                                kimoSort(attributes, 'name_' + $i18n.locale)
                              "
                            >
                              {{ $t("name") }}
                            </th>
                            <th>
                              {{ $t("categories") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(attributes, 'attr_type')"
                            >
                              {{ $t("type") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(attributes, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(attributes, 'status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="7"
                              class="no_data"
                              v-if="load && attributes.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in attributes"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>

                            <td>{{ item["name_" + $i18n.locale] }}</td>
                            <td>
                              {{ item.categories.length }}
                            </td>

                            <td>
                              {{ $t(item.attr_type) }}
                            </td>

                            <td>
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td>
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit attributes')"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <button
                                v-if="$can('edit attributes')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditAttribute(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete attributes')"
                                @click="deleteAttribute(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add attributes')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="add_data.name_ar"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              v-model="add_data.name_en"
                            />
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("attribute_set") }}</label>
                            <select
                              class="form-control custom-select"
                              v-model="add_data.attribute_set_id"
                            >
                              <option selected="" disabled="" value="">{{
                                $t("select")
                              }}</option>
                              <option
                                v-for="(set, i) in attribute_sets"
                                :key="i"
                                :value="set.id"
                                >{{ set["name_" + $i18n.locale] }}</option
                              >
                            </select>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("categories") }}</label>
                            <multiselect
                              selectLabel=""
                              selectedLabel=""
                              deselectLabel=""
                              v-model="add_data.categories"
                              :placeholder="
                                $t('select') + ' ' + $t('categories')
                              "
                              :multiple="true"
                              :options="categories.map(e => e.id)"
                              :custom-label="
                                opt =>
                                  categories.find(x => x.id == opt)[
                                    'cat_name_' + $i18n.locale
                                  ]
                              "
                            ></multiselect>
                          </div>

                          <div class="form-group col-md-12">
                            <label>{{ $t("type") }}</label>
                            <select
                              class="form-control custom-select"
                              v-model="add_data.attr_type"
                            >
                              <option selected="" disabled="" value="">{{
                                $t("select")
                              }}</option>
                              <option
                                v-for="(type, i) in attr_types"
                                :key="i"
                                :value="type"
                                >{{ $t(type) }}</option
                              >
                            </select>
                          </div>

                          <div
                            class="form-group col-md-12"
                            v-if="add_data.attr_type == 'options'"
                          >
                            <label>{{ $t("options") }} | {{ $t("ar") }}</label>
                            <b-tags
                              input-id="tags-basic-3"
                              placeholder=""
                              :add-button-text="$t('add')"
                              :tag-pills="false"
                              v-model="add_data.options_ar"
                            ></b-tags>
                          </div>

                          <div
                            class="form-group col-md-12"
                            v-if="add_data.attr_type == 'options'"
                          >
                            <label>{{ $t("options") }} | {{ $t("en") }}</label>
                            <b-tags
                              input-id="tags-basic-4"
                              placeholder=""
                              :add-button-text="$t('add')"
                              :tag-pills="false"
                              v-model="add_data.options_en"
                            ></b-tags>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addAttribute"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Attributes",
  data() {
    return {
      load: false,
      attributes: [],
      categories: [],
      attribute_sets: [],
      search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: { attribute_set_id: "", attr_type: "" },
      add_disabled: false,
      add_success: "",
      add_error: "",
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      attr_types: ["text", "textarea", "number", "boolean", "options"],
      old_search_key: "",
      search_disabled: false,
      options: [],
      categories_values: []
    };
  },
  created() {
    this.getAttributes();
  },
  watch: {
    currentPage() {
      this.getAttributes();
    }
  },
  methods: {
    getAttributes() {
      return this.axios
        .get("attributes", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.categories = res.result.categories;
            this.attribute_sets = res.result.attribute_sets;
            this.attributes = res.result.attributes.data;
            this.rows = res.result.attributes.total;
            this.perPage = res.result.attributes.per_page;
          }
        })
        .catch(error => {
          this.handleError("getAttributes", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getAttributes().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addAttribute() {
      this.add_disabled = true;
      this.axios
        .post("attributes/add", this.add_data)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getAttributes();
            this.add_data = { category_id: "", attr_type: "" };
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addAttribute", res.message);
          }
        })
        .catch(error => {
          this.handleError("addAttribute", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateAttribute() {
      this.edit_disabled = true;
      this.edit_error = this.edit_success = "";
      this.axios
        .post("attributes/update", { ...this.edit_data, options: this.options })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.getAttributes();
            this.options = res.result.options;
          } else if (res.error_flag == 1) {
            this.edit_error = res.message;
          } else {
            this.handleError("updateAttribute", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateAttribute", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.attributes, "id", id);
      let i = this.findIndex(this.attributes, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("attributes/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.attributes[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteAttribute(id) {
      let index = this.findIndex(this.attributes, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("attributes/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.attributes.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteAttribute", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteAttribute", error);
              });
          }
        });
    },
    addOption() {
      let last_option = this.options[this.options.length - 1];
      if (
        this.options.length == 0 ||
        (last_option.value_ar && last_option.value_en)
      ) {
        this.options.push({
          id: "",
          attribute_id: this.edit_data.id,
          value_ar: "",
          value_en: ""
        });
      }
    },
    deleteOption(i) {
      this.options.splice(i, 1);
    },
    showEditAttribute(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.attributes.find(e => e.id == id) };
      this.$set(
        this.edit_data,
        "categories",
        this.edit_data.categories.map(e => e.id)
      );
      this.options = [...this.edit_data.options];
      this.$refs.edit_attribute_modal.show();
    }
  },
  components: { Multiselect }
};
</script>
<style>
.multiselect__tags {
  font-size: 16px;
  font-weight: 100 !important;
}
</style>
