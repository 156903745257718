<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <img
        src="/images/settings.png"
        alt="dashboard"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">{{ $t("dashboard") }}</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            :src="
              $store.state.user.image_url +
                (isAdmin
                  ? $store.state.user.photo
                  : isAdvertiser
                  ? $store.state.user.photo
                  : $store.state.user.photo)
            "
            @error="$event.target.src = '/images/profile.jpg'"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info" v-if="isAdmin">
          <router-link to="/profile" class="d-block">{{
            $store.state.user["name"]
          }}</router-link>
        </div>
      </div>
      <!-- Sidebar Menu -->
      <nav class="mt-2" v-slimscroll="options">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link to="/" class="nav-link" exact active-class="active">
              <i class="nav-icon fas fa-th"></i>
              <p>
                {{ $t("home") }}
              </p>
            </router-link>
          </li>

          <li class="nav-item" v-if="$can('show orders')">
            <router-link to="/orders" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-shopping-cart"></i>
              <p>
                {{ $t("orders") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show transactions')">
            <router-link
              to="/transactions"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon  fas fa-file"></i>
              <p>
                {{ $t("transactions") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show brands')">
            <router-link to="/brands" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-tags"></i>
              <p>
                {{ $t("brands") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show categories')">
            <router-link
              to="/categories"
              class="nav-link"
              active-class="active"
            >
              <i class="nav-icon fa fa-tags"></i>
              <p>
                {{ $t("categories") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show sizes')">
            <router-link to="/sizes" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-tags"></i>
              <p>
                {{ $t("sizes") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show products')">
            <router-link to="/products" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-shopping-bag"></i>
              <p>
                {{ $t("products") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show discounts')">
            <router-link to="/discounts" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-shopping-bag"></i>
              <p>
                {{ $t("discounts") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show coupons')">
            <router-link to="/coupons" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-shopping-bag"></i>
              <p>
                {{ $t("coupons") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show contacts')">
            <router-link to="/contacts" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-envelope"></i>
              <p>
                {{ $t("contacts") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show users')">
            <router-link to="/users" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-users"></i>
              <p>
                {{ $t("users") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show admins')">
            <router-link to="/admins" class="nav-link" active-class="active">
              <i class="nav-icon fas fa-user-shield"></i>
              <p>
                {{ $t("admins") }}
              </p>
            </router-link>
          </li>
          <li class="nav-item" v-if="$can('show groups')">
            <router-link to="/groups" class="nav-link" active-class="active">
              <i class="nav-icon fa fa-tags"></i>
              <p>
                {{ $t("groups") }}
              </p>
            </router-link>
          </li>

          <li
            v-if="isAdmin"
            :class="
              'nav-item has-treeview mt-2 ' +
                (c_tree == 1 || $route.path.includes('settings')
                  ? 'menu-open'
                  : '')
            "
          >
            <a
              href="#"
              :class="
                'nav-link ' + ($route.path.includes('settings') ? 'active' : '')
              "
              @click="CTree(1)"
            >
              <i class="fa fa-cogs nav-icon"></i>
              <p>
                {{ $t("settings") }}
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              v-if="$can('show settings') || $can('show pages')"
            >
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/settings"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("general") }}</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/email_templates"
                  class="nav-link"
                  active-class="active"
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>
                    {{ $t("email_notifications") }}
                  </p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings') && false">
                <router-link
                  to="/settings/images"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/images') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("images") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings')">
                <router-link
                  to="/settings/slider"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/slider') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("slider") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show banners')">
                <router-link
                  to="/settings/banners"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/banners') ? 'active' : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("banners") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show settings') && false">
                <router-link
                  to="/settings/payment_gates"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/payment_gates')
                        ? 'active'
                        : '')
                  "
                >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ $t("payment_gates") }}</p>
                </router-link>
              </li>
              <li class="nav-item" v-if="$can('show pages')">
                <router-link
                  to="/settings/pages"
                  :class="
                    'nav-link ' +
                      ($route.path.includes('settings/pages') ? 'active' : '')
                  "
                >
                  <i class="nav-icon far fa-circle nav-icon"></i>
                  <p>{{ $t("pages") }}</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a
              :href="$store.state.website_link"
              target="_blank"
              class="nav-link"
            >
              <i class="nav-icon fas fa-globe"></i>
              <p>
                {{ $t("website") }}
              </p>
            </a>
          </li>
          <br />
          <br />
          <br />
          <br />
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      c_tree: "",
      options: {
        height: "100%",
        size: 7,
        color: "#fff"
      }
    };
  },
  methods: {
    CTree(i) {
      if (i == this.c_tree) {
        this.c_tree = "";
      } else {
        this.c_tree = i;
      }
    }
  }
};
</script>
