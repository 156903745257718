<template>
  <div>
    <b-modal
      ref="edit_brand_modal"
      :title="$t('edit') + ' ' + $t('brand_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            variant="danger"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("image") }}</label>
              <div class="image_div" v-if="edit_data_image || edit_data.image">
                <img
                  :src="edit_data_image || image_url + edit_data.image"
                  @error="$event.target.src = '/images/placeholder.jpg'"
                />
                <i
                  v-if="edit_data_image"
                  class="fa fa-times"
                  @click.prevent="edit_data_image = edit_data_icon = null"
                ></i>
              </div>
              <b-form-file
                v-if="!edit_data_image"
                accept="image/*"
                @change.prevent="onImageChange($event, 'edit_data')"
                ref="edit_data_icon"
              ></b-form-file>
              <span class="error invalid-feedback">{{
                (edit_errors.image_input || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.name_ar }"
                v-model="edit_data.name_ar"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.name_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                :class="{ 'is-invalid': edit_errors.name_en }"
                v-model="edit_data.name_en"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.name_en || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateBrand"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("brands") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("brands") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(brands, 'id')">
                              #
                            </th>
                            <th>{{ $t("image") }}</th>
                            <th
                              class="sort"
                              @click="kimoSort(brands, 'name_' + $i18n.locale)"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(brands, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(brands, 'status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="6"
                              class="no_data"
                              v-if="load && brands.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in brands"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>
                            <td>
                              <img
                                :src="image_url + '/' + item.image"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td>{{ item["name_" + $i18n.locale] }}</td>

                            <td>
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td class="pb-0">
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit brands')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit brands')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit brands')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditBrand(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete brands')"
                                @click="deleteBrand(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add brands')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                  {{ $t("brand_2") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("image") }}</label>

                            <div class="image_div" v-if="add_data_image">
                              <img :src="add_data_image" />
                              <i
                                class="fa fa-times"
                                @click.prevent="
                                  add_data_image = add_data_icon = null
                                "
                              ></i>
                            </div>
                            <b-form-file
                              v-else
                              :placeholder="$t('choose_file')"
                              accept="image/*"
                              multiple
                              @change.prevent="
                                onImageChange($event, 'add_data')
                              "
                              ref="add_data_icon"
                            ></b-form-file>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.image_input || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.name_ar }"
                              v-model="add_data.name_ar"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name_ar || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              :class="{ 'is-invalid': add_errors.name_en }"
                              v-model="add_data.name_en"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name_en || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addBrand"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Brands",
  data() {
    return {
      load: false,
      brands: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data_icon_icon: null,
      add_data_icon_image: null,
      edit_data_icon: null,
      edit_data_image: null,
      edit_data_icon_icon: null,
      edit_data_icon_image: null,
      image_url: "",
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {}
    };
  },
  created() {
    this.getBrands();
  },
  watch: {
    currentPage() {
      this.getBrands();
    }
  },
  methods: {
    getBrands() {
      return this.axios
        .get("brands", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.brands = res.result.brands.data;
            this.rows = res.result.brands.total;
            this.perPage = res.result.brands.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getBrands", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getBrands().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addBrand() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
      }
      if (this.add_data_icon_icon) {
        fd.append(
          "icon_input",
          this.add_data_icon_icon,
          this.add_data_icon_icon.name
        );
      }
      Object.keys(this.add_data).forEach(key => {
        fd.append(key, this.add_data[key]);
      });
      this.add_error = this.add_success = "";
      this.add_errors = {};
      this.axios
        .post("brands/add", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getBrands();
            this.add_data = {};
            this.add_data_image = null;
            this.add_data_icon = null;
            this.add_data_icon_image = null;
            this.add_data_icon_icon = null;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addBrand", res.message);
          }
        })
        .catch(error => {
          this.handleError("addBrand", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateBrand() {
      this.edit_disabled = true;
      let fd = new FormData();

      if (this.edit_data_icon) {
        fd.append("image_input", this.edit_data_icon, this.edit_data_icon.name);
      }

      if (this.edit_data_icon_icon) {
        fd.append(
          "icon_input",
          this.edit_data_icon_icon,
          this.edit_data_icon_icon.name
        );
      }

      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.edit_errors = {};
      this.axios
        .post("brands/update", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.edit_data = { ...res.result.brand };
            let i = this.brands.findIndex(e => e.id == this.edit_data.id);
            this.$set(this.brands, i, res.result.brand);
            this.edit_data_image = null;
            this.edit_data_icon = null;
            this.edit_data_icon_image = null;
            this.edit_data_icon_icon = null;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateBrand", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateBrand", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.brands, "id", id);
      let i = this.findIndex(this.brands, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("brands/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.brands[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteBrand(id) {
      let index = this.findIndex(this.brands, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("brands/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.brands.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteBrand", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteBrand", error);
              });
          }
        });
    },
    showEditBrand(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.brands.find(e => e.id == id) };
      this.edit_data_icon = null;
      this.edit_data_image = null;
      this.edit_data_icon_icon = null;
      this.edit_data_icon_image = null;
      this.$refs.edit_brand_modal.show();
    }
  }
};
</script>
