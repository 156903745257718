<template>
  <div>
    <b-modal
      ref="contact_modal"
      :title="$t('contact_2') + ' #' + contact.id"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="col-md-12 contact_det">
          <div class="form-row" v-if="contact.id">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}</label>

              {{ contact.name || (contact.user || {})["name"] }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("phone") }}</label>
              {{ contact.phone || (contact.user || {}).mobile }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("email") }}</label>
              {{ contact.email || (contact.user || {}).email }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("subject") }}</label>
              {{ contact.subject }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("msg") }}</label>
              <pre
                class="p-0 m-0"
              ><p style="font-family:cairo">{{ contact.msg.trim()}}</p></pre>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("date") }}</label>
              {{
                $moment(contact.created_at)
                  .tz($store.state.timezone)
                  .format("Y-MM-DD hh:mm A")
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("contacts") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("contacts") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(contacts, 'id')">
                              #
                            </th>
                            <th>
                              {{ $t("name") }}
                            </th>
                            <th>
                              {{ $t("phone") }}
                            </th>
                            <th>
                              {{ $t("subject") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(contacts, 'is_read')"
                            >
                              {{ $t("status") }}
                            </th>

                            <th
                              class="sort"
                              @click="kimoSort(contacts, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="7"
                              class="no_data"
                              v-if="load && contacts.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in contacts"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>

                            <td>
                              {{ item.name || (item.user || {})["name"] }}
                            </td>
                            <td>
                              {{ item.phone || (item.user || {}).mobile }}
                            </td>
                            <td>{{ item.subject }}</td>
                            <td>
                              <span
                                class="badge badge-info"
                                v-if="item.is_read"
                                >{{ $t("is_read") }}</span
                              >
                              <span class="badge badge-danger" v-else>{{
                                $t("is_new")
                              }}</span>
                            </td>

                            <td>
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit contacts')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('show')"
                                @click="showContact(item.id)"
                              >
                                <i class="fas fa-eye"> </i>
                              </button>
                              <button
                                v-if="$can('delete contacts')"
                                @click="deleteContact(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab
                no-body
                :title="$t('add')"
                v-if="$can('add contacts') && false"
              >
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                  {{ $t("contact_2") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.name }"
                              v-model="add_data.name"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.name || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addContact"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      load: false,
      contacts: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 0,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      contact: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {}
    };
  },
  created() {
    this.getContacts();
  },
  watch: {
    currentPage() {
      this.getContacts();
    }
  },
  methods: {
    getContacts() {
      return this.axios
        .get("contacts", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.contacts = res.result.contacts.data;
            this.rows = res.result.contacts.total;
            this.perPage = res.result.contacts.per_page;
          }
        })
        .catch(error => {
          this.handleError("getContacts", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getContacts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addContact() {
      this.add_disabled = true;
      let fd = new FormData();
      Object.keys(this.add_data).forEach(key => {
        fd.append(key, this.add_data[key]);
      });
      this.add_error = this.add_success = "";
      this.add_errors = {};
      this.axios
        .post("contacts/add", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getContacts();
            this.add_data = {};
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addContact", res.message);
          }
        })
        .catch(error => {
          this.handleError("addContact", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateContact() {
      this.edit_disabled = true;
      let fd = new FormData();
      Object.keys(this.contact).forEach(key => {
        if (this.contact[key]) {
          fd.append(key, this.contact[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.edit_errors = {};
      this.axios
        .post("contacts/update", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.contact = { ...res.result.contact };
            let i = this.contacts.findIndex(e => e.id == this.contact.id);
            this.$set(this.contacts, i, res.result.contact);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateContact", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateContact", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    deleteContact(id) {
      let index = this.findIndex(this.contacts, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("contacts/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.contacts.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteContact", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteContact", error);
              });
          }
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.contacts, "id", id);
      this.axios
        .post("contacts/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.contacts[index].is_read = 1;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {});
    },
    showContact(id) {
      this.contact = { ...this.contacts.find(e => e.id == id) };
      if (!this.contact.is_read) {
        this.changeStatus(id);
      }
      this.$refs.contact_modal.show();
    }
  }
};
</script>
