<template>
  <div>
    <b-modal
      ref="order_modal"
      :title="$t('order_2') + ' #' + order.id"
      header-class="new_modal_header"
      size="lg"
      hide-footer
    >
      <div class="row" style="margin:-15px">
        <div class="col-md-8 order_det" v-if="order">
          <div class="form-row ">
            <div class="form-group col-md-6">
              <label>{{ $t("user") }}</label>
              {{ order.customer_name }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("phone") }}</label>
              {{ order.customer_mobile }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("address") }}</label>
              <b>{{ (order.shipping_city || {})["name_" + $i18n.locale] }}, </b
              >{{ order.shipping_address }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("products") }}:</label>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" v-if="order.products">
              <thead>
                <tr>
                  <th style="vertical-align: middle;width: 10px">#</th>
                  <th style="vertical-align: middle;">{{ $t("name") }}</th>
                  <th>
                    {{ $t("size") }} <br />
                    ( {{ $t("cm") }} )
                  </th>
                  <th style="vertical-align: middle;">{{ $t("qty") }}</th>
                  <th>
                    {{ $t("price") }} <br />
                    ( {{ $t("sar") }} )
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prod, i) in order.products" :key="i">
                  <td>{{ i + 1 }}.</td>
                  <td>{{ prod["name_" + $i18n.locale] }}</td>
                  <td>
                    <span class="badge bg-danger">{{ prod.size }}</span>
                  </td>
                  <td>X {{ prod.qty }}</td>
                  <td>
                    <b>{{ prod.price * prod.qty }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row ">
            <div class="form-group col-md-6">
              <label>{{ $t("sub_total") }}</label>
              {{ order.sub_total }} {{ $t("sar") }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("delivery_fees") }}</label>
              {{ order.delivery_fees }} {{ $t("sar") }}
            </div>
             <div class="form-group col-md-6">
              <label>{{ $t("total") }}</label>
              {{ order.total }} {{ $t("sar") }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("payment_method") }}</label>
              <span
                class="m-1"
                v-for="(transaction, i) in order.transactions"
                :key="i"
                v-tooltip="order.payment_option"
                >{{ $t(transaction.payment_method) }}</span
              >
              <span
                v-if="order.transactions && order.transactions.length == 0"
                v-tooltip="'CASH'"
                >{{ $t("CASH") }}</span
              >
            </div>
            <div class="form-group col-md-6" v-if="false">
              <label>{{ $t("prepared_time") }}</label>
              8 {{ $t("day") }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("notes") }}</label>
              {{ order.notes || "-" }}
            </div>
          </div>
        </div>
        <div class="col-md-4 order-steps">
          <ul
            class="timeline"
            v-if="!['CANCELLED', 'REFUNDED', 'HOLD'].includes(order.status)"
          >
            <li>
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order.created_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
              >
                {{
                  $moment(order.created_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order.created_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <div
                :class="
                  'timeline-content active ' +
                    ([
                      'CONFIRMED',
                      'PREPAIRING',
                      'DELIVERING',
                      'COMPLETED'
                    ].includes(order.status)
                      ? 'process_done'
                      : '')
                "
              >
                {{ $t("order_created") }}
              </div>
            </li>
            <li>
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order.confirmed_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
                v-if="
                  order.confirmed_at &&
                    [
                      'CONFIRMED',
                      'PREPAIRING',
                      'DELIVERING',
                      'COMPLETED'
                    ].includes(order.status)
                "
              >
                {{
                  $moment(order.confirmed_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order.confirmed_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <p class="timeline-date" v-else>--:--</p>
              <div
                :class="
                  'timeline-content ' +
                    ([
                      'CONFIRMED',
                      'PREPAIRING',
                      'DELIVERING',
                      'COMPLETED'
                    ].includes(order.status)
                      ? 'active'
                      : '') +
                    (['PREPAIRING', 'DELIVERING', 'COMPLETED'].includes(
                      order.status
                    )
                      ? ' process_done'
                      : '')
                "
              >
                {{ $t("order_confirmed") }}
              </div>
            </li>
            <li>
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order.prepairing_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
                v-if="
                  order.prepairing_at &&
                    ['PREPAIRING', 'DELIVERING', 'COMPLETED'].includes(
                      order.status
                    )
                "
              >
                {{
                  $moment(order.prepairing_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order.prepairing_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <p class="timeline-date" v-else>--:--</p>
              <div
                :class="
                  'timeline-content ' +
                    (['PREPAIRING', 'DELIVERING', 'COMPLETED'].includes(
                      order.status
                    )
                      ? 'active'
                      : '') +
                    (['DELIVERING', 'COMPLETED'].includes(order.status)
                      ? ' process_done'
                      : '')
                "
              >
                {{ $t("order_prepared") }}
              </div>
            </li>
            <li v-if="!order.take_by_user">
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order.delivering_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
                v-if="
                  ['DELIVERING', 'COMPLETED'].includes(order.status) &&
                    order.delivering_at
                "
              >
                {{
                  $moment(order.delivering_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order.delivering_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <p class="timeline-date" v-else>--:--</p>
              <div
                :class="
                  'timeline-content ' +
                    (['DELIVERING', 'COMPLETED'].includes(order.status)
                      ? 'active'
                      : '') +
                    (['COMPLETED'].includes(order.status)
                      ? ' process_done'
                      : '')
                "
              >
                {{ $t("order_delivered") }}
              </div>
            </li>
            <li>
              <p
                class="timeline-date"
                v-tooltip="
                  $moment(order.completed_at)
                    .tz($store.state.timezone)
                    .format('Y-MM-DD hh:mm A')
                "
                v-if="
                  ['COMPLETED'].includes(order.status) && order.completed_at
                "
              >
                {{
                  $moment(order.completed_at)
                    .tz($store.state.timezone)
                    .format("hh:mm")
                }}
                {{
                  $moment(order.completed_at)
                    .tz($store.state.timezone)
                    .locale($i18n.locale)
                    .format("A")
                }}
              </p>
              <p class="timeline-date" v-else>--:--</p>
              <div
                :class="
                  'timeline-content ' +
                    (['COMPLETED'].includes(order.status) ? 'active' : '')
                "
              >
                {{ $t("order_completed") }}
              </div>
            </li>
            <button
              v-if="order.status != 'COMPLETED' && order.status != 'CANCELLED'"
              class="btn btn-info btn-sm d-block mx-auto"
              @click="updateStatus(order.id)"
            >
              {{ $t("go_to_next_status") }}
              <i
                :class="
                  'm-1 fas fa-arrow-' +
                    ($i18n.locale == 'ar' ? 'left' : 'right')
                "
              ></i>
            </button>
            <button
              v-if="['NEW'].includes(order.status)"
              class="btn btn-danger btn-sm d-block mt-3 mx-auto"
              @click="showCancel(order.id)"
            >
              {{ $t("cancel_order") }}
              <i class="fa fa-times"></i>
            </button>
          </ul>
          <div v-else>
            <br />
            <center>
              <label class="badge badge-info">
                {{ $t(order.status) }}
              </label>
            </center>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="cancel_modal"
      centered
      :title="$t('cancel_order')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="cancel_error || cancel_success ? true : false"
            :variant="cancel_error ? 'danger' : 'success'"
            dismissible
            @dismissed="cancel_error = cancel_success = ''"
          >
            {{ cancel_error || cancel_success }}
          </b-alert>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("c_reason") }}</label>
              <textarea
                class="form-control"
                :class="{ 'is-invalid': cancel_errors.status_reason }"
                v-model="cancel_data.status_reason"
              >
              </textarea>
              <span class="error invalid-feedback">{{
                (cancel_errors.status_reason || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="cancel_disabled"
            @click.prevent="cancelOrder"
          >
            {{ $t("save") }}
            <b-spinner small v-if="cancel_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("orders") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("orders") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card-text>
              <b-card no-body class="card">
                <b-card-header>
                  {{ $t("status") }}:
                  <div class="fom-group" style="display: inline-block;">
                    <select
                      class="form-control pt-0"
                      style="width: max-content;"
                      v-model="order_status"
                    >
                      <option value="">{{ $t("all") }}</option>
                      <option
                        v-for="(status, i) in statuses"
                        :key="i"
                        :value="status"
                      >
                        {{ $t(status) }}
                      </option>
                    </select>
                  </div>
                  <div class="card-tools">
                    <div
                      class="input-group input-group-sm"
                      style="width: 150px;"
                    >
                      <input
                        type="text"
                        class="form-control float-right"
                        :placeholder="$t('search')"
                        v-model="search_key"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          :disabled="search_disabled"
                          @click.prevent="searchAll"
                          class="btn btn-default"
                        >
                          <i
                            class="fa fa-spinner fa-spin"
                            v-if="search_disabled"
                          ></i>
                          <i class="fas fa-search" v-else></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <!-- /.card-header -->
                <b-card-body class="table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="sort" @click="kimoSort(orders, 'id')">
                          #
                        </th>
                        <th class="sort" @click="kimoSort(orders, 'user_id')">
                          {{ $t("user") }}
                        </th>
                        <th class="sort" @click="kimoSort(orders, 'total')">
                          {{ $t("total") }}
                        </th>
                        <th class="sort" @click="kimoSort(orders, 'status')">
                          {{ $t("status") }}
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(orders, 'created_at')"
                        >
                          {{ $t("created_at") }}
                        </th>
                        <th>{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="6" v-if="!load">
                          <i class="fa fa-sync  fa-spin load-con"></i>
                        </td>
                        <td
                          colspan="6"
                          class="no_data"
                          v-if="load && orders.length == 0"
                        >
                          {{ $t("no_data") }}
                        </td>
                      </tr>
                      <tr v-for="(item, i) in orders" :key="i" v-show="load">
                        <td>{{ item.id }}</td>
                        <td>{{ item.customer_name }}</td>
                        <td>
                          {{ item.total }} {{ $t("sar") }}
                          <i
                            class="fas fa-money-bill"
                            v-if="item.transactions.length == 0"
                            v-tooltip="$t('cash')"
                          ></i>
                          <i
                            class="fas fa-credit-card m-1"
                            v-if="
                              item.transactions
                                .map(e => e.payment_method)
                                .includes('CARD')
                            "
                            v-tooltip="$t('card')"
                          ></i>
                          <i
                            class="fas fa-hand-holding-medical m-1"
                            v-if="
                              item.transactions
                                .map(e => e.payment_method)
                                .includes('TAMARA')
                            "
                            v-tooltip="$t('tamara')"
                          ></i>
                        </td>
                        <td>
                          <label class="badge badge-info">
                            {{ $t(item.status) }}
                          </label>
                        </td>
                        <td>
                          {{
                            $moment(item.created_at)
                              .tz($store.state.timezone)
                              .format("Y-MM-DD hh:mm A")
                          }}
                        </td>
                        <td class="actions-group">
                          <button
                            v-if="
                              false &&
                                $can('edit orders') &&
                                item.status != 'REFUNDED' &&
                                item.status != 'HOLD' &&
                                item.transactions.length > 0
                            "
                            class="btn btn-secondary btn-sm"
                            v-tooltip="$t('refund')"
                            @click="refundOrder(item.id)"
                          >
                            <i class="fas fa-undo-alt"></i>
                          </button>
                          <button
                            v-if="$can('edit orders')"
                            class="btn btn-info btn-sm"
                            v-tooltip="$t('show')"
                            @click="showOrder(item.id)"
                          >
                            <i class="fas fa-eye"> </i>
                          </button>
                          <button
                            v-if="$can('delete orders')"
                            @click="deleteOrder(item.id)"
                            class="btn btn-danger btn-sm"
                            v-tooltip="$t('delete')"
                          >
                            <i class="fas fa-trash"> </i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
                <!-- /.card-body -->
                <b-card-footer class="card-footer clearfix">
                  <b-pagination
                    class="m-2"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    :prev-text="$t('previous')"
                    :next-text="$t('next')"
                    align="right"
                    :first-number="true"
                    :last-number="true"
                  ></b-pagination>
                </b-card-footer>
              </b-card>
            </b-card-text>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Orders",
  data() {
    return {
      load: false,
      orders: [],
      order: {},
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      order_status: "",
      cancel_success: "",
      cancel_error: "",
      cancel_errors: {},
      cancel_data: {
        reason_id: ""
      },
      cancel_disabled: false,
      statuses: [
        "NEW",
        "CONFIRMED",
        "PREPAIRING",
        "DELIVERING",
        "COMPLETED",
        "CANCELLED",
        "REFUNDED",
        "HOLD"
      ]
    };
  },
  created() {
    this.getOrders();
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
    order_status() {
      this.getOrders();
    }
  },
  methods: {
    getOrders() {
      this.load = false;
      return this.axios
        .get("orders", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.order_status
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.orders = res.result.orders.data;
            this.rows = res.result.orders.total;
            this.perPage = res.result.orders.per_page;
          }
        })
        .catch(error => {
          this.handleError("getOrders", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getOrders().then(() => {
          this.search_disabled = false;
        });
      }
    },
    updateStatus(id) {
      let index = this.findIndex(this.orders, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("change_order_msg"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("orders/update_status/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.orders[index], "status", res.result.status);
                  let date_key = Object.keys(res.result)[1];
                  this.$set(this.orders[index], date_key, res.result[date_key]);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteOrder", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteOrder", error);
              });
          }
        });
    },
    showCancel(id) {
      this.cancel_success = this.cancel_error = "";
      this.cancel_errors = {};
      this.$set(this.cancel_data, "id", id);
      this.$refs.cancel_modal.show();
    },
    cancelOrder() {
      this.cancel_disabled = true;
      this.cancel_error = "";
      let index = this.orders.findIndex(e => e.id == this.cancel_data.id);
      this.axios
        .post("orders/cancel_order", this.cancel_data)
        .then(response => {
          this.cancel_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.$set(this.orders[index], "status", res.result.status);
            this.$noty.success(res.message);
            this.$refs.order_modal.hide();
            this.$refs.cancel_modal.hide();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.cancel_errors = res.message;
            } else {
              this.cancel_error = res.message;
            }
          } else {
            this.handleError("cancelOrder", res.message);
          }
        })
        .catch(error => {
          this.handleError("cancelOrder", error);
        })
        .finally(() => {
          this.cancel_disabled = false;
        });
    },
    refundOrder(id) {
      let index = this.orders.findIndex(e => e.id == id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("orders/refund", {
                id: id
              })
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.orders[index], "status", "REFUNDED");
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("refundOrder", res.message);
                }
              })
              .catch(error => {
                this.handleError("refundOrder", error);
              });
          }
        });
    },
    deleteOrder(id) {
      let index = this.findIndex(this.orders, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("orders/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.orders.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteOrder", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteOrder", error);
              });
          }
        });
    },
    showOrder(id) {
      this.order = this.orders.find(e => e.id == id);
      this.$refs.order_modal.show();
    }
  }
};
</script>
