import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    dashboard: "Dashboard",
    en: "English",
    ar: "Arabic",
    my_info: "My info",
    logout: "Log Out",
    home: "Home",
    add: "Add",
    edit: "Edit",
    save: "Save",
    delete: "Delete",
    change_status: "Change status",
    all: "All",
    website: "Go to website",
    products: "Products",
    products_2: "Products",
    product: "Product",
    product_2: "Product",
    name: "Name",
    image: "Image",
    desc: "Description",
    created_at: "Created at",
    created_at_r: "Reservation date",
    status: "Status",
    login: "Sign in",
    login_desc: "Sign in to the control panel",
    search: "Search",
    phone: "Phone",
    birth_date: "Birth date",
    experience: "Experience",
    education: "Education",
    email: "E-mail",
    actions: "Actions",
    on: "On",
    soon: "Soon",
    off: "Off",
    previous: "Previous",
    next: "Next",
    no_data: "No data found",
    select: "Select",
    ok: "OK",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    error: "Error",
    error_msg: "An Unexpected Error Occurred",
    choose_file: "Choose a file",
    choose_image: "Choose an image",
    brand: "Brand",
    brand_2: "Brand",
    brands: "Brands",
    brands_2: "Brands",
    category: "Category",
    category_2: "Category",
    categories: "Categories",
    categories_2: "Categories",
    sub_category: "Sub category",
    sub_category_2: "Sub category",
    sub_categories: "Sub categories",
    sub_categories_2: "Sub categories",
    are_you_sure: "Are you sure?",
    password: "Password",
    c_password: "Current password",
    n_password: "New password",
    v_password: "Verify new password",
    show: "Show",
    info: "Information",
    price: "Price",
    image_must_image: "The Image must be an image format",
    reservations: "Reservations",
    egp: "EGP",
    settings: "Settings",
    images: "Images",
    home_page: "Home page",
    url: "Url",
    website_info: "Website info",
    logo: "Logo",
    site_keywords: "Keywords",
    contact_info: "Contacts info",
    address: "Address",
    addresses: "Addresses",
    pages: "Pages",
    page: "Page",
    page_2: "Page",
    title: "Title",
    content: "Content",
    payment_gate: "Current Payment gate",
    payment_gates: "Payment gates",
    new_method: "New method",
    m_apps: "Apps",
    m_app: "App",
    m_app_2: "App",
    amount: "Amount",
    admins: "Admins",
    admin: "Admin",
    admin_2: "Admin",
    password_v: "Verify password",
    groups: "Groups",
    group: "Group",
    group_2: "Group",
    permissions: "Permissions",
    g_section: "Section",
    view: "View",
    otp: "OTP",
    error_mobile: "Cannot verify the mobile number",
    error_otp: "Wrong verification code",
    app_info: "App info",
    copy: "Copy",
    copied: "Copied",
    two_factors: "Two authentication",
    menu: "Menu",
    texts: "Texts",
    footet_text: "Footer text",
    timezone: "TimeZone",
    cairo: "Cairo",
    riyadh: "Riyadh",
    my_zone: "My timezone",
    countries: "Countries",
    balance: "Balance",
    current_balance: "Current balance",
    extra_balance: "Extra balance",
    payment_method: "Payment method",
    pay: "Pay",
    send: "Send",
    last_updated: "Last updated",
    succeeded: "Succeeded",
    failed: "Failed",
    notes: "Notes",
    pending: "Pending",
    balance_info: "Balance info",
    av_balance: "Available balance",
    posts: "Posts",
    posts_2: "Posts",
    post: "Post",
    post_2: "Post",
    type: "Type",
    text: "Text",
    textarea: "Textarea",
    number: "Number",
    options: "Options",
    options_2: "Options",
    boolean: "Yes / No",
    attributes: "Attributes",
    attribute: "Attribute",
    attribute_2: "Attribute",
    attribute_sets: "Attribute sets",
    attribute_set: "Attribute set",
    attribute_set_2: "Attribute set",
    city: "City",
    hour_price: "Hour price",
    day_price: "Day price",
    sar: "SAR",
    firstly_select_category: "Firstly select category",
    users: "Clients",
    user: "Client",
    slider: "Slider",
    slides: "Slides",
    slides_2: "Slides",
    slide: "Slide",
    slide_2: "Slide",
    banners: "Banners",
    banners_2: "Banners",
    banner: "Banner",
    banner_2: "Banner",
    sizes: "Sizes",
    sizes_2: "Sizes",
    size: "Size",
    size_2: "Size",
    coupons: "Coupons",
    coupons_2: "Coupons",
    coupon: "Coupon",
    coupon_2: "Coupon",
    discounts: "Discounts",
    discounts_2: "Discounts",
    discount_2: "Discount",
    icon: "Icon",
    slide_url: "Link",
    r_type: "Reservation type",
    day: "Day",
    hour: "Hour",
    quantity: "Quantity",
    qty: "Quantity",
    sku: "SKU",
    discount: "Discount ",
    discount_type: "Discount type",
    fixed_value: "Fixed",
    percentage: "Percentage",
    discount_start: "Start at",
    discount_end: "End at",
    discount_value: "Discount value",
    in_stock: "In stock",
    orders: "Orders",
    orders_2: "Orders",
    order: "Order",
    order_2: "Order",
    NEW: "New",
    CONFIRMED: "Confirmed",
    PREPAIRING: "Prepairing",
    DELIVERING: "Delivering",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
    order_created: "Order created",
    order_confirmed: "Order Confirmed",
    order_prepared: "Prepairing order",
    order_delivered: "Delivering",
    order_completed: "Completed",
    cm: "Cm",
    delivery_fees: "Delivery fees",
    prepared_time: "Prepared time",
    go_to_next_status: "Go to next status",
    total: "Total",
    sub_total: "sub Total",
    change_order_msg: "Are you sure to change order status ?",
    search_select: "Search & select",
    min_spend: "Min spend",
    max_spend: "Max spend",
    coupon_limit: "Limit",
    user_limit: "Limit to each user",
    contacts: "Messages",
    contacts_2: "Messages",
    contact: "Message",
    contact_2: "Message",
    reviews: "Reviews",
    reviews_2: "Reviews",
    review: "Review",
    review_2: "Review",
    subject: "Subject",
    is_read: "Read",
    is_new: "New",
    msg: "Message",
    date: "Date",
    rating: "Rating",
    comment: "Comment",
    add_new_size: "Add new size",
    best_sellers_products: "Best sellers products",
    general: "General",
    email_notifications: "E-mail Notifications",
    tamara: "Tamara",
    TAMARA: "TAMARA",
    CARD: "CARD",
    CASH: "CASH",
    card: "CARD",
    cash: "CASH",
    refund: "Refund",
    cancel_order: "Cancel order",
    c_reasons: "Cancellation reasons",
    c_reason: "Cancellation reason",
    c_reason_2: "Cancellation reason",
    REFUNDED: "REFUNDED",
    transactions: "Transactions",
    hold: "Hold",
    HOLD: "Hold"
  },
  ar: {
    dashboard: "لوحة التحكم",
    ar: "عربي",
    en: "إنجليزي",
    my_info: "بياناتي",
    logout: "تسجيل خروج",
    home: "الرئيسية",
    add: "إضافة",
    edit: "تعديل",
    save: "حفـظ",
    delete: "حـذف",
    change_status: "تغيير الحالة",
    all: "الكــل",
    website: "الانتقال للموقع",
    products: "المنتجات",
    products_2: "منتجات",
    product: "منتج",
    product_2: "المنتج",
    name: "الاسم",
    image: "الصورة",
    desc: "الوصف",
    created_at: "تاريخ الإضافة",
    created_at_r: "تاريخ الحجز",
    status: "الحالة",
    login: "دخول",
    login_desc: "سجل دخولك للوحة التحكم",
    search: "بحث",
    phone: "رقم الهاتف",
    birth_date: "تاريخ الميلاد",
    experience: "الخبرة",
    education: "التعليم",
    email: "البريد الإلكتروني",
    actions: "الإجراءات",
    on: "نشـط",
    soon: "قريباً",
    off: "غير نشـط",
    previous: "السابق",
    next: "التالي",
    no_data: "لا توجـد بيانات",
    select: "اختر",
    ok: "موافق",
    cancel: "إلغاء",
    yes: "نعم",
    no: "لا",
    error: "خطأ",
    error_msg: "حدث خطأ ما، حاول مرة أخرى",
    choose_file: "اختر الملف",
    choose_image: "اختر الصورة",
    brand: "الماركة",
    brand_2: "ماركة",
    brands: "الماركات",
    brands_2: "ماركات",
    category: "القسم",
    category_2: "قسم",
    categories: "الأقسام",
    categories_2: "أقسام",
    sub_category: "القسم الفرعي",
    sub_category_2: "قسم فرعي",
    sub_categories: "الأقسام الفرعية",
    sub_categories_2: "أقسام قرعية",
    section: "قسم المقالات",
    section_2: "قسم مقالات",
    sections: "أقسام المقالات",
    sections_2: "أقسام مقالات",
    are_you_sure: "هل أنت متأكد؟",
    password: "كلمة المرور",
    c_password: "كلمة المرور الحالية",
    n_password: "كلمة المرور الجديدة",
    v_password: "تأكيد كلمة المرور الجديدة",
    show: "عرض",
    info: "البيانات",
    price: "السعر",
    image_must_image: "الصورة يجب أن تكون بصيغة صورة",
    reservations: "الحجوزات",
    egp: "جنية",
    settings: "الإعدادات",
    images: "الصور",
    home_page: "الصفحة الرئيسية",
    url: "الرابط",
    website_info: "بيانات الموقع",
    logo: "الشعار",
    site_keywords: "الكلمات المفتاحية",
    contact_info: "بيانات التواصل",
    address: "العنوان",
    addresses: "العناوين",
    pages: "الصفحات الفرعية",
    page: "الصفحة",
    page_2: "صفحة",
    title: "العنوان",
    content: "المحتوى",
    payment_gate: "بوابة الدفع الحالية",
    payment_gates: "بوابات الدفع",
    payment_methods: "وسائل الدفع",
    amount: "المبلغ",
    admins: "المديرين",
    admin: "مدير",
    admin_2: "المدير",
    password_v: "تأكيد كلمة المرور",
    groups: "المجموعات",
    group: "مجموعة",
    group_2: "المجموعة",
    permissions: "الصلاحيات",
    g_section: "القسم",
    view: "عرض",
    otp: "رمز التحقق",
    error_mobile: "لا يمكننا التحقق من رقم الجوال",
    error_otp: "خطأ في رمز التحقق",
    app_info: "بيانات التطبيق",
    copy: "نسخ",
    copied: "تم النسخ",
    two_factors: "مصادقة ثنائية",
    menu: "القائمة",
    texts: "النصوص",
    footet_text: "نص الفوتر",
    timezone: "المنطقة الزمنية",
    cairo: "القاهرة",
    riyadh: "الرياض",
    my_zone: "منطقتي الزمنية",
    countries: "الدول",
    balance: "الرصيد",
    current_balance: "الرصيد الحالي",
    extra_balance: "الرصيد الإضافي",
    payment_method: "طريقة الدفع",
    pay: "دفع",
    send: "ارسال",
    last_updated: "أخر تحديث",
    succeeded: "تم التنفيذ",
    failed: "فشلت",
    notes: "ملاحظات",
    pending: "في الانتظار",
    balance_info: "بيانات الرصيد",
    av_balance: "الرصيد المتاح",
    posts: "المقالات",
    posts_2: "مقالات",
    post: "المقالة",
    post_2: "مقالة",
    type: "النوع",
    text: "نص",
    textarea: "نص كبير",
    number: "رقم",
    options: "خيارات",
    options_2: "الخيارات",
    boolean: "نعم / لا",
    attributes: "السمات",
    attribute: "السمة",
    attribute_2: "سمة",
    attribute_sets: "مجموعات السمات",
    attribute_set: "مجموعة سمات",
    attribute_set_2: "مجموعة السمات",
    city: "المدينة",
    hour_price: "سعر الساعة",
    day_price: "سعر اليوم",
    sar: "ريال",
    firstly_select_category: "أختر القسم أولاً",
    users: "العملاء",
    users_2: "عملاء",
    user: "العميل",
    user_2: "عميل",
    slider: "السلايدر",
    slides: "الصور",
    slides_2: "صور",
    slide: "الصورة",
    slide_2: "صورة",
    banners: "البنرات",
    banners_2: "بنرات",
    banner: "البانر",
    banner_2: "بانر",
    sizes: "الأحجام",
    sizes_2: "أحجام",
    size: "الحجم",
    size_2: "حجم",
    coupons: "الكوبونات",
    coupons_2: "كوبون",
    coupon: "الكوبون",
    coupon_2: "كوبون",
    discounts: "الخصومات",
    discounts_2: "خصومات",
    discount_2: "خصم",
    icon: "الايقونة",
    slide_url: "الرابط",
    r_type: "نوع الحجز",
    day: "يوم",
    hour: "ساعة",
    quantity: "الكمية",
    qty: "الكمية",
    sku: "SKU",
    discount: "الخصم",
    discount_type: "نوع الخصم",
    fixed_value: "قيمة",
    percentage: "نسبة",
    discount_start: "يبدأ من",
    discount_end: "ينتهي في",
    discount_value: "قيمة الخصم",
    in_stock: "متوفر",
    orders: "الطلبات",
    orders_2: "طلبات",
    order: "الطلب",
    order_2: "طلب",
    NEW: "جديد",
    CONFIRMED: "مؤكد",
    PREPAIRING: "يتم تجهيزه",
    DELIVERING: "يتم توصيله",
    COMPLETED: "مُكتمـل",
    CANCELLED: "ملغي",
    order_created: "إنشاء الطلب",
    order_confirmed: "تأكيد الطلب",
    order_prepared: "تجهييز الطلب",
    order_delivered: "بدأ التوصيل",
    order_completed: "تم التسليم",
    cm: "سـم",
    prepared_time: "وقت الإعداد",
    go_to_next_status: "الانتقال للحالة التالية",
    total: "الإجمالي",
    sub_total: "المجموع الفرعي",
    change_order_msg: "متأكد من تغيير حالة الطلب ؟",
    search_select: "ابحث واختر",
    min_spend: "أقل قيمة",
    max_spend: "أقصى قيمة",
    coupon_limit: "مرات الإستخدام",
    user_limit: "مرات استخدام كل عضو",
    contacts: "الرسائل",
    contacts_2: "رسائل",
    contact: "الرسالة",
    contact_2: "رسالة",
    reviews: "التقييمات",
    reviews_2: "تقييمات",
    review: "التقييم",
    review_2: "تقييم",
    subject: "الموضوع",
    is_read: "مقرؤة",
    is_new: "جديدة",
    msg: "الرسالة",
    date: "التاريخ",
    rating: "التقييم",
    comment: "التعليق",
    add_new_size: "إضافة حجم جديد",
    best_sellers_products: "المنتجات الأعلى مبيعاً",
    email_notifications: "اشعارات الايميل",
    general: "العامة",
    tamara: "Tamara",
    TAMARA: "TAMARA",
    CARD: "بطاقة",
    CASH: "كاش",
    card: "بطاقة",
    cash: "كاش",
    refund: "ارجاع المبلغ",
    cancel_order: "إلغاء الطلب",
    c_reasons: "أسباب الإلغاء",
    c_reason: "سبب الإلغاء",
    c_reason_2: "سبب إلغاء",
    REFUNDED: "مُسترجعـه",
    transactions: "المعاملات",
    hold: "معلق",
    HOLD: "معلق",
    delete_size: "حذف",
    delivery_fees: "رسوم التوصيل"
  }
};

export let i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages // set locale messages
});
