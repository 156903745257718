import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app_name: "Wesadaco",
    api_url:
      process.env.NODE_ENV != "production"
        ? "http://wesadaco.test/api"
        : "https://wesada.co/api",
    title_suffix: "Wesadaco",
    error_path: "login",
    website_link: "",
    access_token: null,
    user: null,
    user_type: null,
    debug: process.env.NODE_ENV != "production" ? true : false,
    alert_error: true,
    permissions: [],
    timezone: "Asia/Riyadh"
  },
  getters: {},
  mutations: {
    setAccessToken(state, data) {
      state.access_token = data;
    },
    setUserType(state, data) {
      state.user_type = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setWebsiteLink(state, data) {
      state.website_link = data;
    },
    setPermissions(state, data) {
      state.permissions = data;
    },
    setAlertError(state, data) {
      state.alert_error = data;
    },
    setTimezone(state, data) {
      state.timezone = data;
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => {
          try {
            return ls.get(key);
          } catch {
            return null;
          }
        },
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
      reducer: state => ({
        user: state.user,
        access_token: state.access_token,
        user_type: state.user_type,
        timezone: state.timezone
      })
    })
  ]
});
