<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("info") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item">{{ $t("settings") }}</li>
              <li class="breadcrumb-item active">{{ $t("info") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content mt-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card no-body>
              <b-card-body>
                <i class="fa fa-sync  fa-spin load-con" v-if="!load"></i>
                <form class="col-md-8" v-show="load">
                  <b-alert
                    :show="error || success ? true : false"
                    :variant="error ? 'danger' : 'success'"
                    dismissible
                    @dismissed="error = success = ''"
                  >
                    {{ error || success }}
                  </b-alert>

                  <b-tabs no-key-nav>
                    <b-tab :title="$t('website_info')">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>{{ $t("logo") }}</label>
                          <div class="image_div" v-if="logo_image">
                            <img :src="logo_image" />
                            <i
                              class="fa fa-times"
                              @click.prevent="logo_image = logo_icon = null"
                            ></i>
                          </div>
                          <div class="image_div" v-if="!logo_image">
                            <img
                              :src="image_url + info.site_logo"
                              @error="
                                $event.target.src = '/images/placeholder.jpg'
                              "
                            />
                          </div>
                          <b-form-file
                            v-if="!logo_image"
                            :placeholder="$t('choose_image')"
                            accept="image/*"
                            @change.prevent="onImageChange($event, 'logo')"
                            ref="logo_icon"
                          ></b-form-file>
                        </div>

                        <div class="form-group col-md-6">
                          <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                          <input
                            class="form-control"
                            v-model="info.site_name_ar"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label>{{ $t("name") }} | {{ $t("en") }}</label>
                          <input
                            class="form-control ltr"
                            v-model="info.site_name_en"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label>{{ $t("desc") }} | {{ $t("ar") }}</label>
                          <textarea
                            rows="5"
                            class="form-control"
                            v-model="info.site_desc_ar"
                          ></textarea>
                        </div>

                        <div class="form-group col-md-6">
                          <label>{{ $t("desc") }} | {{ $t("en") }}</label>
                          <textarea
                            rows="5"
                            class="form-control ltr"
                            v-model="info.site_desc_en"
                          ></textarea>
                        </div>

                        <div class="form-group col-md-12">
                          <label>{{ $t("site_keywords") }}</label>
                          <b-tags
                            input-id="tags-basic"
                            placeholder=""
                            :add-button-text="$t('add')"
                            :tag-pills="true"
                            v-model="info.site_keywords"
                          ></b-tags>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab :title="$t('contact_info')">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>{{ $t("phone") }}</label>
                          <input
                            class="form-control ltr"
                            v-model="info.phone"
                          />
                        </div>
                        <div class="form-group col-md-12">
                          <label>Facebook</label>
                          <input class="form-control ltr" v-model="info.fb" />
                        </div>
                        <div class="form-group col-md-12">
                          <label>Twitter</label>
                          <input class="form-control ltr" v-model="info.tw" />
                        </div>
                        <div class="form-group col-md-12">
                          <label>Instagram</label>
                          <input
                            class="form-control ltr"
                            v-model="info.insta"
                          />
                        </div>
                      </div>
                    </b-tab>

                    <b-tab :title="$t('addresses')">
                      <br />

                      <b-tabs>
                        <b-tab :title="i" v-for="i in 5" :key="i">
                          <br />
                          <div class="form-row ">
                            <div class="form-group col-md-12">
                              <label
                                >{{ $t("address") }} | {{ $t("ar") }}</label
                              >
                              <input
                                class="form-control"
                                v-model="info['address_' + i + '_ar']"
                              />
                            </div>

                            <div class="form-group col-md-12">
                              <label
                                >{{ $t("address") }} | {{ $t("en") }}</label
                              >
                              <input
                                class="form-control ltr"
                                v-model="info['address_' + i + '_en']"
                              />
                            </div>

                            <div
                              class="col-md-12"
                              style="padding:0;"
                              v-if="false"
                            >
                              <GmapMap
                                :center="{
                                  lat: info.lat,
                                  lng: info.lng
                                }"
                                :zoom="8"
                                map-type-id="terrain"
                                :options="{
                                  mapTypeControl: false,
                                  streetViewControl: false
                                }"
                                style=" height: 250px"
                              >
                                <GmapMarker
                                  :position="{
                                    lat: info.lat,
                                    lng: info.lng
                                  }"
                                  :draggable="true"
                                  @dragend="updatePosition"
                                />
                              </GmapMap>
                            </div>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </b-tab>
                    <b-tab :title="$t('texts')">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-6">
                          <label
                            >{{ $t("footet_text") }} | {{ $t("ar") }}</label
                          >
                          <textarea
                            class="form-control"
                            v-model="info.footer_text_ar"
                            rows="3"
                          ></textarea>
                        </div>
                        <div class="form-group col-md-6">
                          <label
                            >{{ $t("footet_text") }} | {{ $t("en") }}</label
                          >
                          <textarea
                            class="form-control ltr"
                            v-model="info.footer_text_en"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>

                  <button
                    type="submit"
                    class="btn btn-info mt-2"
                    :disabled="disabled"
                    @click="updateInfo"
                  >
                    {{ $t("save") }}
                    <b-spinner small v-if="disabled"></b-spinner>
                  </button>
                </form>
              </b-card-body>
              <!-- /.card-body -->
            </b-card>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      load: false,
      disabled: false,
      success: "",
      error: "",
      add_progress: 0,
      image_url: "",
      info: {
        lat: 1,
        lng: 1
      },
      logo_image: null,
      logo_icon: null
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      return this.axios
        .get("settings/info")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.info = res.result.info;
            this.info.lat = parseFloat(this.info.lat);
            this.info.lng = parseFloat(this.info.lng);
            this.info.site_keywords = this.info.site_keywords
              ? this.info.site_keywords.split(",")
              : [];
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getInfo", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateInfo() {
      this.disabled = true;
      let fd = new FormData();
      if (this.logo_icon) {
        fd.append("logo_input", this.logo_icon, this.logo_icon.name);
      }
      Object.keys(this.info).forEach(key => {
        if (this.info[key]) {
          fd.append(key, this.info[key]);
        }
      });
      this.error = this.success = "";
      this.axios
        .post("settings/update_info", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.success = res.message;
            this.$set(this.info, "site_logo", res.result.site_logo);
            this.logo_image = null;
            this.logo_icon = null;
          } else if (res.error_flag == 1) {
            this.error = res.message;
          } else {
            this.handleError("updateInfo", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateInfo", error);
        })
        .finally(() => {
          this.disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updatePosition(event) {
      this.$set(this.info, "lat", event.latLng.lat());
      this.$set(this.info, "lng", event.latLng.lng());
    }
  }
};
</script>
