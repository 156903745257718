<template>
  <div>
    <b-modal
      ref="edit_email_template_modal"
      id="edit_email_template_modal"
      :title="edit_data['name']"
      header-class="new_modal_header"
      hide-footer
      no-enforce-focus
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.name"
                :class="{ 'is-invalid': edit_errors.name }"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.name || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("image") }}</label>
              <div class="image_div" v-if="edit_data_image || edit_data.image">
                <img
                  :src="edit_data_image || image_url + edit_data.image"
                  @error="$event.target.src = '/images/placeholder.jpg'"
                />
                <i
                  v-if="edit_data_image"
                  class="fa fa-times"
                  @click.prevent="edit_data_image = edit_data_icon = null"
                ></i>
              </div>
              <b-form-file
                v-if="!edit_data_image"
                accept="image/*"
                @change.prevent="onImageChange($event, 'edit_data')"
                ref="edit_data_icon"
              ></b-form-file>
              <span class="error invalid-feedback">{{
                (edit_errors.image_input || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("title") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.title_ar"
                :class="{ 'is-invalid': edit_errors.title_ar }"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.title_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("title") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.title_en"
                :class="{ 'is-invalid': edit_errors.title_en }"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.title_en || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("desc") }} | {{ $t("ar") }}</label>
              <textarea
                class="form-control"
                v-model="edit_data.desc_ar"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("desc") }} | {{ $t("en") }}</label>
              <textarea
                class="form-control"
                v-model="edit_data.desc_en"
              ></textarea>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateEmailTemplate"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th class="sort" @click="sortTable('name')">
                              {{ $t("name") }}
                            </th>
                            <th>
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="4"
                              class="no_data"
                              v-if="load && email_templates.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in email_templates"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ i + 1 }}</td>

                            <td>{{ item.name }}</td>
                            <td class="pb-0">
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit settings')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit settings')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit settings')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditemail_template(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
              <b-tab
                no-body
                :title="$t('add')"
                v-if="$can('add email_templates') && false"
              >
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                  {{ $t("email_template_2") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <b-tabs>
                          <b-tab :title="$t('ar')">
                            <br />
                            <div class="form-row ">
                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("title") }} | {{ $t("ar") }}</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="add_data.email_template_name_ar"
                                />
                              </div>

                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("content") }} | {{ $t("ar") }}</label
                                >
                              </div>
                            </div>
                          </b-tab>
                          <b-tab :title="$t('en')">
                            <br />
                            <div class="form-row ">
                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("title") }} | {{ $t("en") }}</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="add_data.email_template_name_en"
                                />
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>

                        <div class="form-group col-md-6">
                          <label>{{ $t("menu") }}</label>
                          <select
                            class="form-control pt-0"
                            v-model="add_data.menu"
                          >
                            <option value="1"> 1</option>
                            <option value="2">2</option>
                          </select>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addemail_template"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "EmailTemplates",
  data() {
    return {
      load: false,
      email_templates: [],
      search_fields: ["email_template_name_ar"],
      search_key: "",
      disabled_s: [],
      currentEmailTemplate: 1,
      perEmailTemplate: 1,
      rows: 1,
      add_data: { menu: "1" },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      sort_key: "",
      sort_type: "asc",
      search_disabled: false,
      old_search_key: "",
      edit_data_image: null,
      edit_data_icon: null,
      image_url: ""
    };
  },
  created() {
    this.getEmailTemplates();
  },
  methods: {
    getEmailTemplates() {
      return this.axios
        .get("email_templates", {
          params: {
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.email_templates = res.result.email_templates;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getEmailTemplates", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getEmailTemplates();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getEmailTemplates().then(() => {
          this.search_disabled = false;
        });
      }
    },
    updateEmailTemplate() {
      let index = this.findIndex(this.email_templates, "id", this.edit_data.id);
      let fd = new FormData();

      if (this.edit_data_icon) {
        fd.append("image_input", this.edit_data_icon, this.edit_data_icon.name);
      }

      if (this.edit_data_icon_icon) {
        fd.append(
          "icon_input",
          this.edit_data_icon_icon,
          this.edit_data_icon_icon.name
        );
      }

      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });

      this.edit_disabled = true;
      this.edit_error = this.edit_success = "";
      this.axios
        .post("email_templates/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_errors = {};
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.$set(this.email_templates, index, res.result.email_template);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateEmailTemplate", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateEmailTemplate", error);
        })
        .finally(() => {
          this.edit_disabled = false;
          document.getElementById("edit_email_template_modal").scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.email_templates, "id", id);
      let i = this.findIndex(this.email_templates, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("email_templates/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.email_templates[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },

    showEditemail_template(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = { ...this.email_templates.find(e => e.id == id) };
      this.$refs.edit_email_template_modal.show();
    }
  }
};
</script>
