import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home";
import Products from "../views/Products";
import Users from "../views/Users";
import User from "../views/User";
import Product from "../views/Product";
import Attributes from "../views/Attributes";
import AttributeSets from "../views/AttributeSets";
import Brands from "../views/Brands";
import Sizes from "../views/Sizes";
import Contacts from "../views/Contacts";
import Discounts from "../views/Discounts";
import Coupons from "../views/Coupons";
import Categories from "../views/Categories";
import SubCategories from "../views/SubCategories";
import Sections from "../views/Sections";
import Posts from "../views/Posts";
import Reservations from "../views/Reservations";
import Profile from "../views/Profile";
import Slider from "../views/settings/Slider";
import Banners from "../views/settings/Banners";
import Images from "../views/settings/Images";
import Info from "../views/settings/Info";
import Pages from "../views/settings/Pages";
import EmailTemplates from "../views/settings/EmailTemplates";
import Settings from "../views/Settings";
import PaymentGates from "../views/settings/PaymentGates";
import Admins from "../views/Admins";
import Groups from "../views/Groups";
import Orders from "../views/Orders";
import Transactions from "../views/Transactions";
import Login from "../views/Login.vue";
import Error from "../views/Error.vue";

import auth from "../middleware/auth.js";
import can from "../middleware/can.js";
import is_admin from "../middleware/admin.js";
//import is_merchant from "../middleware/merchant.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "login" }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { middleware: [auth], title: "dashboard" }
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      middleware: [auth, is_admin, can],
      title: "orders",
      permission: "orders"
    }
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    meta: {
      middleware: [auth, is_admin, can],
      title: "transactions",
      permission: "transactions"
    }
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      middleware: [auth, is_admin, can],
      title: "products",
      permission: "products"
    }
  },
  {
    path: "/products/:id",
    name: "Product",
    component: Product,
    meta: {
      middleware: [auth, is_admin, can],
      title: "product_2",
      permission: "products"
    }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      middleware: [auth, is_admin, can],
      title: "users",
      permission: "users"
    }
  },
  {
    path: "/users/:id",
    name: "User",
    component: User,
    meta: {
      middleware: [auth, is_admin, can],
      title: "user_2",
      permission: "users"
    }
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brands,
    meta: {
      middleware: [auth, is_admin, can],
      title: "brands",
      permission: "brands"
    }
  },
  {
    path: "/sizes",
    name: "Sizes",
    component: Sizes,
    meta: {
      middleware: [auth, is_admin, can],
      title: "sizes",
      permission: "sizes"
    }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      middleware: [auth, is_admin, can],
      title: "contacts",
      permission: "contacts"
    }
  },
  {
    path: "/discounts",
    name: "Discounts",
    component: Discounts,
    meta: {
      middleware: [auth, is_admin, can],
      title: "discounts",
      permission: "discounts"
    }
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
    meta: {
      middleware: [auth, is_admin, can],
      title: "coupons",
      permission: "coupons"
    }
  },
  {
    path: "/categories",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth, is_admin, can],
      title: "categories",
      permission: "categories"
    }
  },
  {
    path: "/sub_categories",
    name: "SubCategories",
    component: SubCategories,
    meta: {
      middleware: [auth, is_admin, can],
      title: "sub_categories",
      permission: "sub_categories"
    }
  },
  {
    path: "/attribute_sets",
    name: "AttributeSets",
    component: AttributeSets,
    meta: {
      middleware: [auth, is_admin, can],
      title: "attribute_sets",
      permission: "attributes"
    }
  },
  {
    path: "/attributes",
    name: "Attributes",
    component: Attributes,
    meta: {
      middleware: [auth, is_admin, can],
      title: "attributes",
      permission: "attributes"
    }
  },
  {
    path: "/sections",
    name: "Sections",
    component: Sections,
    meta: {
      middleware: [auth, is_admin, can],
      title: "sections",
      permission: "sections"
    }
  },
  {
    path: "/posts",
    name: "Posts",
    component: Posts,
    meta: {
      middleware: [auth, is_admin, can],
      title: "posts",
      permission: "posts"
    }
  },
  {
    path: "/reservations",
    name: "Reservations",
    component: Reservations,
    meta: {
      middleware: [auth, can],
      title: "reservations",
      permission: "reservations"
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { middleware: [auth], title: "my_info" }
  },
  {
    path: "/admins",
    name: "Admins",
    component: Admins,
    meta: {
      middleware: [auth, is_admin, can],
      title: "admins",
      permission: "admins"
    }
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: {
      middleware: [auth, is_admin, can],
      title: "groups",
      permission: "groups"
    }
  },
  {
    path: "/settings/info",
    name: "Info",
    component: Info,
    meta: {
      middleware: [auth, is_admin, can],
      title: "info",
      permission: "settings"
    }
  },
  {
    path: "/settings/slider",
    name: "Slider",
    component: Slider,
    meta: {
      middleware: [auth, is_admin, can],
      title: "slider",
      permission: "settings"
    }
  },
  {
    path: "/settings/banners",
    name: "Banners",
    component: Banners,
    meta: {
      middleware: [auth, is_admin, can],
      title: "banners",
      permission: "banners"
    }
  },
  {
    path: "/settings/images",
    name: "Images",
    component: Images,
    meta: {
      middleware: [auth, is_admin, can],
      title: "images",
      permission: "settings"
    }
  },
  {
    path: "/settings/payment_gates",
    name: "PaymentGates",
    component: PaymentGates,
    meta: {
      middleware: [auth, is_admin, can],
      title: "payment_gates",
      permission: "settings"
    }
  },

  {
    path: "/settings/pages",
    name: "Pages",
    component: Pages,
    meta: {
      middleware: [auth, is_admin, can],
      title: "pages",
      permission: "pages"
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      middleware: [auth, is_admin, can],
      title: "settings",
      permission: "settings"
    }
  },
  {
    path: "/email_templates",
    name: "EmailTemplates",
    component: EmailTemplates,
    meta: {
      middleware: [auth, is_admin, can],
      title: "email_notifications",
      permission: "settings"
    }
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    meta: { title: "error" }
  },
  {
    path: "*",
    name: "ErrorPage",
    component: Error,
    meta: { title: "error" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return savedPosition || { x: 0, y: 0 };
    }
  }
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});

export default router;
