<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" @click="toggleMenu" href="#"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>
    <div></div>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <b-nav-item-dropdown right v-if="false">
        <template slot="button-content">
          <i
            class="fa fa-clock"
            v-tooltip="$t('timezone')"
            style="font-size:18px;color:green"
          ></i>
        </template>
        <b-dropdown-item @click="changeTimezone('Africa/Cairo')">
          {{ $t("cairo") }}</b-dropdown-item
        >
        <b-dropdown-item @click="changeTimezone('Asia/Riyadh')">{{
          $t("riyadh")
        }}</b-dropdown-item>
        <b-dropdown-item @click="changeTimezone($moment.tz.guess(true))">{{
          $t("my_zone")
        }}</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown right v-if="false">
        <template slot="button-content">
          <i
            class="far fa-bell"
            v-tooltip="$t('timezone')"
            style="font-size:18px;color:green"
          ></i>
        </template>
        <b-dropdown-item @click="changeTimezone('Africa/Cairo')">
          {{ $t("cairo") }}</b-dropdown-item
        >
        <b-dropdown-item @click="changeTimezone('Asia/Riyadh')">{{
          $t("riyadh")
        }}</b-dropdown-item>
        <b-dropdown-item @click="changeTimezone($moment.tz.guess(true))">{{
          $t("my_zone")
        }}</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown right>
        <template slot="button-content">
          <i class="fa fa-language" style="font-size:22px;color:blue"></i>
        </template>
        <b-dropdown-item @click="changeLang('ar')">
          {{ $t("ar") }}</b-dropdown-item
        >
        <b-dropdown-item @click="changeLang('en')">{{
          $t("en")
        }}</b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown right>
        <template slot="button-content">
          <i class="fa fa-user-circle" style="font-size:22px"></i>
        </template>
        <b-dropdown-item to="/profile"> {{ $t("my_info") }}</b-dropdown-item>
        <b-dropdown-item @click="logOut">{{ $t("logout") }}</b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown" v-if="false">
        <a class="nav-link" data-toggle="dropdown" @click="c_menu = 2" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div
          :class="
            'dropdown-menu dropdown-menu-lg dropdown-menu-right ' +
              (c_menu == 2 ? 'show' : '')
          "
        >
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer"
            >See All Notifications</a
          >
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      c_menu: ""
    };
  },
  methods: {
    toggleMenu() {
      if (document.body.classList.contains("sidebar-collapse")) {
        document.body.classList.remove("sidebar-collapse");
        document.body.classList.add("sidebar-open");
      } else {
        document.body.classList.remove("sidebar-open");
        document.body.classList.add("sidebar-collapse");
      }
    }
  }
};
</script>
