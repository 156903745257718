<template>
  <div>
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card">
        <div class="card card-outline card-primary">
          <div class="card-header text-center">
            <a class="h1">{{ $store.state.app_name }}</a>
          </div>

          <div class="card-body">
            <p class="login-box-msg">{{ $t("login_desc") }}</p>
            <form>
              <b-alert :show="error ? true : false" variant="danger">
                <i class="icon fas fa-exclamation-triangle"></i>
                {{ error }}</b-alert
              >
              <div class="input-group mb-3" v-show="!this.$route.query.e">
                <input
                  type="email"
                  class="form-control ltr"
                  placeholder="Email"
                  v-model="login_data.email"
                  :disabled="otp_exist"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control ltr"
                  placeholder="Password"
                  v-model="login_data.password"
                  :disabled="otp_exist"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3" v-if="otp_exist">
                <input
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  type="number"
                  class="form-control"
                  :placeholder="$t('otp')"
                  v-model="otp"
                  maxlength="6"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fa fa-key"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- /.col -->
                <div class="col-4 mx-auto">
                  <button
                    type="submit"
                    :disabled="disabled"
                    class="btn btn-primary btn-block btn-flat"
                    @click.prevent="login"
                  >
                    {{ $t("login") }}
                    <b-spinner small v-if="disabled"></b-spinner>
                  </button>
                </div>
                <!-- /.col -->
              </div>
            </form>
            <div id="recaptcha-container"></div>
          </div>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
export default {
  name: "APP",
  data() {
    return {
      login_data: {},
      error: "",
      disabled: false,
      appVerifier: "",
      otp: "",
      otp_exist: false,
      confirmationResult: null,
      access_token: null
    };
  },
  created() {
    document.body.classList.add("hold-transition", "login-page");

    if (this.$route.query.e) {
      this.$set(this.login_data, "email", this.$route.query.e);
    }
  },
  mounted() {},
  methods: {
    login() {
      this.disabled = true;
      this.axios
        .post("login", this.login_data)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$store.commit("setAccessToken", res.result.access_token);
            this.$store.commit("setUserType", res.result.user_type);
            if (res.result.user_type == "admin") {
              this.$store.commit("setPermissions", res.result.permissions);
            } else {
              this.$store.commit("setPermissions", []);
            }
            let profile = res.result.profile;
            profile.image_url = res.result.image_url;
            this.$store.commit("setUser", profile);
            this.$store.commit("setWebsiteLink", res.result.website_link);
            this.$router.push({ path: "/" });
          } else {
            this.error = res.message;
          }
        })
        .finally(() => {
          this.disabled = false;
        });
    }
  }
};
</script>
