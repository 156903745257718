<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("images") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item">{{ $t("settings") }}</li>
              <li class="breadcrumb-item active">{{ $t("images") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content mt-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card no-body>
              <b-card-body>
                <i class="fa fa-sync  fa-spin load-con" v-if="!load"></i>

                <b-tabs no-key-nav>
                  <b-tab :title="$t('home_page')">
                    <br />
                    <b-tabs no-key-nav vertical pills>
                      <form class="col-md-8" v-show="load">
                        <b-alert
                          :show="error || success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="error = success = ''"
                        >
                          {{ error || success }}
                        </b-alert>

                        <b-tab title="1">
                          <div class="form-row ">
                            <div class="form-group col-md-12">
                              <div
                                class="image_div"
                                v-if="banners_image.home_banner_1"
                              >
                                <img :src="banners_image.home_banner_1" />
                                <i
                                  class="fa fa-times"
                                  @click.prevent="
                                    banners_image.home_banner_1 = banners_icon.home_banner_1 = null
                                  "
                                ></i>
                              </div>
                              <div
                                class="image_div"
                                v-if="!banners_image.home_banner_1"
                              >
                                <img
                                  :src="image_url + settings.home_banner_1"
                                  @error="
                                    $event.target.src =
                                      '/images/placeholder.jpg'
                                  "
                                />
                              </div>
                              <b-form-file
                                v-if="!banners_image.home_banner_1"
                                :placeholder="$t('choose_image')"
                                accept="image/*"
                                @change.prevent="
                                  onImageChange(
                                    $event,
                                    'banners',
                                    'home_banner_1'
                                  )
                                "
                                ref="banners_icon"
                              ></b-form-file>
                            </div>
                            <div class="form-group col-md-12">
                              <label>{{ $t("url") }}</label>
                              <input
                                class="form-control ltr"
                                v-model="settings.home_banner_1_url"
                              />
                            </div>
                          </div>
                        </b-tab>
                        <b-tab title="2">
                          <div class="form-row ">
                            <div class="form-group col-md-12">
                              <div
                                class="image_div"
                                v-if="banners_image.home_banner_2"
                              >
                                <img :src="banners_image.home_banner_2" />
                                <i
                                  class="fa fa-times"
                                  @click.prevent="
                                    banners_image.home_banner_2 = banners_icon.home_banner_2 = null
                                  "
                                ></i>
                              </div>
                              <div
                                class="image_div"
                                v-if="!banners_image.home_banner_2"
                              >
                                <img
                                  :src="image_url + settings.home_banner_2"
                                  @error="
                                    $event.target.src =
                                      '/images/placeholder.jpg'
                                  "
                                />
                              </div>
                              <b-form-file
                                v-if="!banners_image.home_banner_2"
                                :placeholder="$t('choose_image')"
                                accept="image/*"
                                @change.prevent="
                                  onImageChange(
                                    $event,
                                    'banners',
                                    'home_banner_2'
                                  )
                                "
                                ref="banners_icon"
                              ></b-form-file>
                            </div>
                            <div class="form-group col-md-12">
                              <label>{{ $t("url") }}</label>
                              <input
                                class="form-control ltr"
                                v-model="settings.home_banner_2_url"
                              />
                            </div>
                          </div>
                        </b-tab>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="disabled"
                          @click="updateBanners"
                        >
                          {{ $t("save") }}
                          <b-spinner small v-if="disabled"></b-spinner>
                        </button>
                      </form>
                    </b-tabs>
                  </b-tab>
                  <b-tab :title="$t('payment_methods')">
                    <br />

                    <form class="col-md-8" v-show="load">
                      <b-alert
                        :show="error || success ? true : false"
                        variant="danger"
                        dismissible
                        @dismissed="error = success = ''"
                      >
                        {{ error || success }}
                      </b-alert>
                      <div class="form-row">
                        <draggable
                          v-model="payment_methods"
                          @change="orderMethods()"
                          :class="'form-group col-md-12'"
                        >
                          <div
                            class="image_div mb-3"
                            v-for="(method, m) in payment_methods"
                            :key="m"
                          >
                            <img
                              class="mb-1"
                              style="height:50px"
                              :src="image_url + method.photo"
                              @error="
                                $event.target.src = '/images/placeholder.jpg'
                              "
                            />
                            <i
                              class="fa fa-times"
                              v-tooltip="$t('delete')"
                              @click.prevent="deletePaymentMethod(method.id)"
                            ></i>
                          </div>
                        </draggable>

                        <div class="form-group col-md-12">
                          <label>{{ $t("new_method") }}</label>
                          <b-spinner
                            small
                            class="d-block"
                            variant="success"
                            v-if="up_method"
                          ></b-spinner>
                          <b-form-file
                            v-else
                            :placeholder="$t('choose_image')"
                            accept="image/*"
                            @change.prevent="addPaymentMethod"
                            ref="new_method_icon"
                          ></b-form-file>
                        </div>
                      </div>
                    </form>
                  </b-tab>
                </b-tabs>
              </b-card-body>
              <!-- /.card-body -->
            </b-card>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "Images",
  data() {
    return {
      load: false,
      disabled: false,
      success: "",
      error: "",
      add_progress: 0,
      image_url: "",
      settings: {},
      banners_image: {},
      banners_icon: {},
      payment_methods: [],
      up_method: false
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      return this.axios
        .get("settings")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.settings = res.result.settings;
            this.payment_methods = res.result.payment_methods;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getSettings", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateBanners() {
      this.disabled = true;
      let fd = new FormData();

      Object.keys(this.banners_icon).forEach(key => {
        if (this.banners_icon[key]) {
          fd.append(
            "images[" + key + "]",
            this.banners_icon[key],
            this.banners_icon[key].name
          );
        }
      });
      if (this.settings.home_banner_1_url) {
        fd.append("home_banner_1_url", this.settings.home_banner_1_url);
      } else {
        fd.append("home_banner_1_url", "");
      }

      if (this.settings.home_banner_2_url) {
        fd.append("home_banner_2_url", this.settings.home_banner_2_url);
      } else {
        fd.append("home_banner_2_url", "");
      }
      this.axios
        .post("settings/update_images", fd)
        .then(response => {
          let res = response.data;
          this.error = this.success = "";
          if (res.error_flag == 0) {
            this.success = res.message;
            this.settings = res.result.settings;
            this.banners_image = {};
            this.banners_icon = {};
          } else if (res.error_flag == 1) {
            this.error = res.message;
          } else {
            this.handleError("updateBanners", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateBanners", error);
        })
        .finally(() => {
          this.disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    addPaymentMethod(e) {
      let fd = new FormData();
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      let icon = files[0];

      if (icon) {
        if (!this.isImage(icon)) {
          this.$refs.new_method_icon.reset();
          return this.alert(this.$t("image_must_image"), this.$t("error"));
        }

        fd.append("payment_method_input", icon, icon.name);
      }

      this.up_method = true;
      this.axios
        .post("settings/add_payment_method", fd)
        .then(response => {
          let res = response.data;

          if (res.error_flag == 0) {
            this.payment_methods.push({ ...res.result.payment_method });
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("addPaymentMethod", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("addPaymentMethod", error);
        })
        .finally(() => {
          this.up_method = false;
        });
    },
    deletePaymentMethod(id) {
      let index = this.findIndex(this.payment_methods, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("settings/delete_payment_method/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.payment_methods.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deletePaymentMethod", res.message);
                }
              })
              .catch(error => {
                this.handleError("deletePaymentMethod", error);
              });
          }
        });
    },
    orderMethods() {
      let order_array = this.payment_methods.map(e => e.id);

      this.axios
        .post("settings/order_payment_methods", { order: order_array })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
          } else {
            this.handleError("orderMethods", res.message);
          }
        })
        .catch(error => {
          this.handleError("orderMethods", error);
        });
    }
  },
  components: {
    draggable
  }
};
</script>
