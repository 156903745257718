<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("payment_gates") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item">{{ $t("settings") }}</li>
              <li class="breadcrumb-item active">{{ $t("payment_gates") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content mt-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card no-body>
              <b-card-body>
                <i class="fa fa-sync  fa-spin load-con" v-if="!load"></i>
                <form class="col-md-8" v-show="load">
                  <b-alert
                    :show="error || success ? true : false"
                    variant="danger"
                    dismissible
                    @dismissed="error = success = ''"
                  >
                    {{ error || success }}
                  </b-alert>

                  <div class="form-group col-md-12">
                    <label>{{ $t("payment_gate") }}</label>
                    <select
                      class="form-control pt-0 pb-0"
                      v-model="info.payment_gate"
                    >
                      <option value="stripe">Stripe</option>
                      <option value="vapulus">Vapulus</option>
                    </select>
                  </div>

                  <b-tabs no-key-nav>
                    <b-tab title="Stripe">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>Public key</label>
                          <input
                            class="form-control ltr"
                            v-model="info.stripe_public_key"
                          />
                        </div>

                        <div class="form-group col-md-12">
                          <label>Secret key</label>
                          <input
                            class="form-control ltr"
                            v-model="info.stripe_secret_key"
                          />
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Vapulus">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>App Id</label>
                          <input
                            class="form-control ltr"
                            v-model="info.vapulus_app_id"
                          />
                        </div>

                        <div class="form-group col-md-12">
                          <label>App password</label>
                          <input
                            class="form-control ltr"
                            v-model="info.vapulus_app_password"
                          />
                        </div>
                        <div class="form-group col-md-12">
                          <label>Secure hash</label>
                          <input
                            class="form-control ltr"
                            v-model="info.vapulus_secure_hash"
                          />
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Paymob">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>API key</label>
                          <textarea
                            class="form-control ltr"
                            v-model="info.paymob_token"
                            rows="4"
                          ></textarea>
                        </div>

                        <div class="form-group col-md-12">
                          <label>HMAC</label>
                          <input
                            class="form-control ltr"
                            v-model="info.paymob_hmac"
                          />
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Payop">
                      <br />

                      <div class="form-row ">
                        <div class="form-group col-md-12">
                          <label>Public key</label>
                          <input
                            class="form-control ltr"
                            v-model="info.payop_public_key"
                          />
                        </div>

                        <div class="form-group col-md-12">
                          <label>Secret key</label>
                          <input
                            class="form-control ltr"
                            v-model="info.payop_secret_key"
                          />
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>

                  <button
                    type="submit"
                    class="btn btn-info mt-2"
                    :disabled="disabled"
                    @click="updateInfo"
                  >
                    {{ $t("save") }}
                    <b-spinner small v-if="disabled"></b-spinner>
                  </button>
                </form>
              </b-card-body>
              <!-- /.card-body -->
            </b-card>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "PaymentGates",
  data() {
    return {
      load: false,
      disabled: false,
      success: "",
      error: "",
      add_progress: 0,
      info: {}
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      return this.axios
        .get("settings/info")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.info = res.result.info;
          }
        })
        .catch(error => {
          this.handleError("getInfo", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateInfo() {
      this.disabled = true;
      let fd = new FormData();
      Object.keys(this.info).forEach(key => {
        if (this.info[key]) {
          fd.append(key, this.info[key]);
        }
      });
      this.axios
        .post("settings/update_payment_gates", fd)
        .then(response => {
          let res = response.data;
          this.error = this.success = "";
          if (res.error_flag == 0) {
            this.success = res.message;
          } else if (res.error_flag == 1) {
            this.error = res.message;
          } else {
            this.handleError("updateInfo", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateInfo", error);
        })
        .finally(() => {
          this.disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    }
  }
};
</script>
