<template>
  <div>
    <b-modal
      ref="edit_item_modal"
      :title="$t('edit') + ' ' + $t('item')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            variant="danger"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}}}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.item_name"
              />
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("desc") }} | {{ $t("ar") }}</label>
              <textarea
                class="form-control"
                rows="4"
                v-model="edit_data.item_desc_ar"
              ></textarea>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("desc") }} | {{ $t("en") }}</label>
              <textarea
                class="form-control ltr"
                rows="4"
                v-model="edit_data.item_desc_en"
              ></textarea>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("price") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                v-model="edit_data.item_price"
              />
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateItem"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>
    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div v-else>
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h1>
                {{ $t("user_2") }} [
                {{ user["name_" + $i18n.locale] || "..." }} ]
              </h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ $t("home") }}</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/users">{{ $t("users") }}</router-link>
                </li>
                <li class="breadcrumb-item active">{{ $t("user") }}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-tabs
                pills
                card
                no-key-nav
                content-class="custom-card"
                nav-wrapper-class="custom-nav-card"
              >
                <b-tab no-body :title="$t('add')" active>
                  <template v-slot:title>
                    <i class="fa fa-pen ml-2 mr-2"></i>
                    {{ $t("info") }}
                  </template>
                  <b-card-text>
                    <b-card no-body>
                      <b-card-body>
                        <form class="col-md-8">
                          <b-alert
                            :show="info_error || info_success ? true : false"
                            variant="danger"
                            dismissible
                            @dismissed="info_error = info_success = ''"
                          >
                            {{ info_error || info_success }}
                          </b-alert>

                          <div>
                            <b-tabs content-class="mt-3" no-key-nav>
                              <b-tab :title="$t('info')" active>
                                <div class="form-row ">
                                  <div class="form-group col-md-12">
                                    <label>{{ $t("image") }}</label>
                                    <div
                                      class="image_div"
                                      v-if="info_image || user.photo"
                                    >
                                      <img
                                        :src="
                                          info_image || image_url + user.photo
                                        "
                                        @error="
                                          $event.target.src =
                                            '/images/placeholder.jpg'
                                        "
                                      />
                                      <i
                                        v-if="info_image"
                                        class="fa fa-times"
                                        @click.prevent="
                                          info_image = null;
                                          info_icon = null;
                                        "
                                      ></i>
                                    </div>
                                    <b-form-file
                                      v-if="!info_image"
                                      accept="image/*"
                                      @change.prevent="
                                        onImageChange($event, 'info')
                                      "
                                      ref="info_icon"
                                    ></b-form-file>
                                  </div>
                                  <div class="form-group col-md-12">
                                    <label>{{ $t("name") }}</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="user.name"
                                    />
                                  </div>
                                  <div class="form-group col-md-12">
                                    <label>{{ $t("city") }}</label>
                                    <select
                                      class="form-control custom-select"
                                      v-model="user.city_id"
                                    >
                                      <option
                                        selected=""
                                        disabled=""
                                        value=""
                                        >{{ $t("select") }}</option
                                      >
                                      <option
                                        v-for="(city, i) in cities"
                                        :key="i"
                                        :value="city.id"
                                        >{{
                                          city["name_" + $i18n.locale]
                                        }}</option
                                      >
                                    </select>
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label>{{ $t("email") }}</label>
                                    <input
                                      type="text"
                                      class="form-control ltr"
                                      v-model="user.email"
                                    />
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label>{{ $t("phone") }}</label>
                                    <input
                                      type="number"
                                      min="0"
                                      class="form-control ltr"
                                      v-model="user.mobile"
                                    />
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label>{{ $t("password") }}</label>
                                    <input
                                      type="password"
                                      class="form-control ltr"
                                      v-model="user.password"
                                    />
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label>{{ $t("password_v") }}</label>
                                    <input
                                      type="password"
                                      class="form-control ltr"
                                      v-model="user.password_confirmation"
                                    />
                                  </div>
                                </div>
                              </b-tab>

                              <b-tab :title="$t('address')">
                                <div class="form-row col-md-12">
                                  <div class="form-group col-md-12">
                                    <label>{{ $t("address") }}</label>
                                    <textarea
                                      class="form-control"
                                      v-model="user.address"
                                    ></textarea>
                                  </div>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>

                          <button
                            type="submit"
                            class="btn btn-info mt-2"
                            :disabled="info_disabled"
                            @click.prevent="updateUser"
                          >
                            {{ $t("save") }}
                            <b-spinner small v-if="info_disabled"></b-spinner>
                          </button>
                        </form>
                      </b-card-body>
                      <!-- /.card-body -->
                    </b-card>
                  </b-card-text></b-tab
                >
              </b-tabs>

              <!-- /.card -->
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      load: false,
      user: [],
      disabled_s: [],
      add_disabled: false,
      add_success: "",
      add_error: "",
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      info_disabled: false,
      info_success: "",
      info_error: "",
      add_progress: 0,
      user_icon: null,
      user_image: null,
      info_icon: null,
      info_image: null,
      image_url: "",
      cities: [],
      disabled: false,
      id: ""
    };
  },
  created() {
    this.id = parseInt(this.$route.params.id);
    this.getUser();
  },
  computed: {},
  watch: {},
  methods: {
    getUser() {
      return this.axios
        .get("users/" + this.id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.cities = res.result.cities;
            this.user = res.result.user;
            this.$set(this.user, "lat", parseFloat(this.user.lat));
            this.$set(this.user, "lng", parseFloat(this.user.lng));
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getUser", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateUser() {
      this.info_disabled = true;
      let fd = new FormData();

      if (this.info_icon) {
        fd.append("photo_input", this.info_icon, this.info_icon.name);
      }
      Object.keys(this.user).forEach(key => {
        if (this.user[key]) {
          fd.append(key, this.user[key]);
        }
      });
      this.info_error = this.info_success = "";
      this.axios
        .post("users/update", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.info_success = res.message;
            this.info_image = null;
            this.info_icon = null;
            this.user = res.result.user;
            this.$set(this.user, "lat", parseFloat(this.user.lat));
            this.$set(this.user, "lng", parseFloat(this.user.lng));
          } else if (res.error_flag == 1) {
            this.info_error = res.message;
          } else {
            this.handleError("updateUser", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateUser", error);
        })
        .finally(() => {
          this.info_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.items, "id", id);
      let i = this.findIndex(this.items_f, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("items/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.items[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
