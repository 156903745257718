<template>
  <div>
    <b-modal
      ref="transaction_modal"
      :title="' #' + transaction.id"
      header-class="new_modal_header"
      size="lg"
      hide-footer
    >
      <div
        class="row"
        style="margin:-15px;white-space: pre-wrap;direction: ltr;text-align: left;word-break: break-word;"
      >
        {{ transaction.payment_details }}
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("transactions") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("transactions") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card-text>
              <b-card no-body class="card">
                <b-card-header>
                  <div class="card-tools">
                    <div class="input-group input-group-sm">
                      <input
                        type="text"
                        class="form-control float-right"
                        :placeholder="$t('search')"
                        v-model="search_key"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          :disabled="search_disabled"
                          @click.prevent="searchAll"
                          class="btn btn-default"
                        >
                          <i
                            class="fa fa-spinner fa-spin"
                            v-if="search_disabled"
                          ></i>
                          <i class="fas fa-search" v-else></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <!-- /.card-header -->
                <b-card-body class="table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="sort" @click="kimoSort(transactions, 'id')">
                          #
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(transactions, 'order_id')"
                        >
                          {{ $t("user") }}
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(transactions, 'order_id')"
                        >
                          {{ $t("order") }}
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(transactions, 'amount')"
                        >
                          {{ $t("amount") }}
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(transactions, 'status')"
                        >
                          {{ $t("status") }}
                        </th>
                        <th
                          class="sort"
                          @click="kimoSort(transactions, 'created_at')"
                        >
                          {{ $t("created_at") }}
                        </th>
                        <th>{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="7" v-if="!load">
                          <i class="fa fa-sync  fa-spin load-con"></i>
                        </td>
                        <td
                          colspan="7"
                          class="no_data"
                          v-if="load && transactions.length == 0"
                        >
                          {{ $t("no_data") }}
                        </td>
                      </tr>
                      <tr
                        v-for="(item, i) in transactions"
                        :key="i"
                        v-show="load"
                      >
                        <td>{{ item.id }}</td>
                        <td>
                          {{ (item.order || {}).customer_name }} <br />
                          {{ (item.order || {}).customer_mobile }}
                        </td>
                        <td>{{ item.order_id || "-" }}</td>
                        <td>
                          {{ item.amount }} {{ $t("sar") }}
                          <i
                            class="fas fa-credit-card m-1"
                            v-if="item.payment_method == 'CARD'"
                            v-tooltip="item.payment_option"
                          ></i>
                          <i
                            class="fas fa-hand-holding-medical m-1"
                            v-if="item.payment_method == 'TAMARA'"
                            v-tooltip="item.payment_option"
                          ></i>
                        </td>
                        <td>
                          <label class="badge badge-info">
                            {{ item.status.toUpperCase() }}
                          </label>
                        </td>
                        <td>
                          {{
                            $moment(item.created_at)
                              .tz($store.state.timezone)
                              .format("Y-MM-DD hh:mm A")
                          }}
                        </td>
                        <td class="actions-group">
                          <button
                            v-if="
                              $can('edit transactions') &&
                                (item.status == 'success' ||
                                  item.status == 'captured')
                            "
                            class="btn btn-secondary btn-sm"
                            v-tooltip="$t('refund')"
                            @click="refundTransaction(item.id)"
                          >
                            <i class="fas fa-undo-alt"></i>
                          </button>
                          <button
                            v-if="$can('edit transactions')"
                            class="btn btn-info btn-sm"
                            v-tooltip="$t('show')"
                            @click="showTransaction(item.id)"
                          >
                            <i class="fas fa-eye"> </i>
                          </button>
                          <button
                            v-if="$can('delete transactions')"
                            @click="deleteTransaction(item.id)"
                            class="btn btn-danger btn-sm"
                            v-tooltip="$t('delete')"
                          >
                            <i class="fas fa-trash"> </i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-card-body>
                <!-- /.card-body -->
                <b-card-footer class="card-footer clearfix">
                  <b-pagination
                    class="m-2"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    :prev-text="$t('previous')"
                    :next-text="$t('next')"
                    align="right"
                    :first-number="true"
                    :last-number="true"
                  ></b-pagination>
                </b-card-footer>
              </b-card>
            </b-card-text>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Transactions",
  data() {
    return {
      load: false,
      transactions: [],
      transaction: {},
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      transaction_status: "",
      cancel_success: "",
      cancel_error: "",
      cancel_errors: {},
      cancel_data: {
        reason_id: ""
      },
      cancel_disabled: false,
      statuses: [
        "NEW",
        "CONFIRMED",
        "PREPAIRING",
        "DELIVERING",
        "COMPLETED",
        "CANCELLED",
        "REFUNDED"
      ]
    };
  },
  created() {
    this.getTransactions();
  },
  watch: {
    currentPage() {
      this.getTransactions();
    },
    transaction_status() {
      this.getTransactions();
    }
  },
  methods: {
    getTransactions() {
      this.load = false;
      return this.axios
        .get("transactions", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            status: this.transaction_status
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.transactions = res.result.transactions.data;
            this.rows = res.result.transactions.total;
            this.perPage = res.result.transactions.per_page;
          }
        })
        .catch(error => {
          this.handleError("getTransactions", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getTransactions().then(() => {
          this.search_disabled = false;
        });
      }
    },
    refundTransaction(id) {
      let index = this.transactions.findIndex(e => e.id == id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("transactions/refund", {
                id: id
              })
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.$set(this.transactions[index], "status", "REFUNDED");
                  this.$set(
                    this.transactions[index],
                    "payment_details",
                    res.result.payment_details
                  );
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("refundTransaction", res.message);
                }
              })
              .catch(error => {
                this.handleError("refundTransaction", error);
              });
          }
        });
    },
    deleteTransaction(id) {
      let index = this.findIndex(this.transactions, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("transactions/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.transactions.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteTransaction", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteTransaction", error);
              });
          }
        });
    },
    showTransaction(id) {
      this.transaction = this.transactions.find(e => e.id == id);
      this.$refs.transaction_modal.show();
    }
  }
};
</script>
