export default function admin({ next, store }) {
  if (store.state.access_token) {
    if (store.state.user_type == "admin") {
      return next();
    } else {
      return next({
        name: "Error"
      });
    }
  }
}
