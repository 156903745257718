<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("users") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("users") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(users, 'id')">
                              #
                            </th>
                            <th>{{ $t("image") }}</th>
                            <th class="sort" @click="kimoSort(users, 'name')">
                              {{ $t("name") }}
                            </th>
                            <!--
                            <th
                              class="sort"
                              @click="kimoSort(users, 'email')"
                            >
                              {{ $t("email") }}
                            </th>-->
                            <th
                              class="sort"
                              @click="kimoSort(users, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th class="sort" @click="kimoSort(users, 'status')">
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="7"
                              class="no_data"
                              v-if="load && users.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr v-for="(item, i) in users" :key="i" v-show="load">
                            <td>{{ item.id }}</td>
                            <td>
                              <img
                                :src="image_url + '/' + item.photo"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td>{{ item["name"] }}</td>
                            <!-- <td>{{ item["email"] || "-" }}</td>-->

                            <td>
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td>
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit users')"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <router-link
                                v-if="$can('edit users')"
                                :to="'/users/' + item.id"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </router-link>
                              <button
                                v-if="$can('delete users')"
                                @click="deleteUser(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add users')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div>
                          <b-tabs content-class="mt-3" no-key-nav>
                            <b-tab :title="$t('info')" active>
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("image") }}</label>

                                  <div class="image_div" v-if="add_data_image">
                                    <img :src="add_data_image" />
                                    <i
                                      class="fa fa-times"
                                      @click.prevent="
                                        add_data_image = null;
                                        add_data_icon = null;
                                      "
                                    ></i>
                                  </div>
                                  <b-form-file
                                    v-else
                                    :placeholder="$t('choose_file')"
                                    accept="image/*"
                                    @change.prevent="
                                      onImageChange($event, 'add_data')
                                    "
                                    ref="add_data_icon"
                                  ></b-form-file>
                                </div>
                                <div class="form-group col-md-12">
                                  <label>{{ $t("name") }}}}</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="add_data.name"
                                  />
                                </div>

                                <div class="form-group col-md-12">
                                  <label>{{ $t("city") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    v-model="add_data.city_id"
                                  >
                                    <option selected="" disabled="" value="">{{
                                      $t("select")
                                    }}</option>
                                    <option
                                      v-for="(city, i) in cities"
                                      :key="i"
                                      :value="city.id"
                                      >{{
                                        city["name_" + $i18n.locale]
                                      }}</option
                                    >
                                  </select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("email") }}</label>
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    v-model="add_data.email"
                                  />
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("phone") }}</label>
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    v-model="add_data.mobile"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("password") }}</label>
                                  <input
                                    type="password"
                                    class="form-control ltr"
                                    v-model="add_data.password"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("password_v") }}</label>
                                  <input
                                    type="password"
                                    class="form-control ltr"
                                    v-model="add_data.password_confirmation"
                                  />
                                </div>
                              </div>
                            </b-tab>

                            <b-tab :title="$t('address')">
                              <div class="form-row col-md-12">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("address") }}</label>
                                  <textarea
                                    class="form-control"
                                    v-model="add_data.address"
                                  ></textarea>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addUser"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      load: false,
      users: [],
      search_key: "",
      old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data: {
        city_id: ""
      },
      image_url: "",
      cities: [],
      search_disabled: false,
      disabled: false
    };
  },
  created() {
    this.getUsers();
  },
  watch: {
    currentPage() {
      this.getUsers();
    }
  },
  methods: {
    getUsers() {
      return this.axios
        .get("users", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.cities = res.result.cities;
            this.users = res.result.users.data;
            this.rows = res.result.users.total;
            this.perPage = res.result.users.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getUsers", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getUsers().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addUser() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("photo_input", this.add_data_icon, this.add_data_icon.name);
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.axios
        .post("users/add", fd)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getUsers();
            this.add_data = {
              city_id: "",
              lat: 1,
              lng: 1
            };
            this.add_data_image = null;
            this.add_data_icon = null;
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addUser", res.message);
          }
        })
        .catch(error => {
          this.handleError("addUser", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.users, "id", id);
      let i = this.findIndex(this.users, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("users/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.users[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteUser(id) {
      let index = this.findIndex(this.users, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("users/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.users.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteUser", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteUser", error);
              });
          }
        });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
