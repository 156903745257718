<template>
  <div>
    <br />
    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <!-- Main content -->
    <section class="content" v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6" v-if="$can('show orders')">
            <!-- small box -->
            <router-link to="/orders">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{ dashboard.orders_count || 0 }}</h3>
                  <p>{{ $t("orders") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-shopping-cart"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->

          <div class="col-lg-3 col-6" v-if="$can('show products')">
            <!-- small box -->
            <router-link to="/products">
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>{{ dashboard.products_count || 0 }}</h3>
                  <p>{{ $t("products") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-shopping-bag"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show categories')">
            <!-- small box -->
            <router-link to="/categories">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{ dashboard.categories_count || 0 }}</h3>
                  <p>{{ $t("categories") }}</p>
                </div>
                <div class="icon">
                  <i class="fa fa-tags"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show brands')">
            <!-- small box -->
            <router-link to="/brands">
              <div class="small-box bg-primary">
                <div class="inner">
                  <h3>{{ dashboard.brands_count || 0 }}</h3>
                  <p>{{ $t("brands") }}</p>
                </div>
                <div class="icon">
                  <i class="fa fa-tags"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show users')">
            <!-- small box -->
            <router-link to="/users">
              <div class="small-box bg-info">
                <div class="inner">
                  <h3>{{ dashboard.users_count || 0 }}</h3>
                  <p>{{ $t("users") }}</p>
                </div>
                <div class="icon">
                  <i class="fa fa-users"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show contacts')">
            <!-- small box -->
            <router-link to="/contacts">
              <div class="small-box bg-success">
                <div class="inner">
                  <h3>{{ dashboard.messages_count || 0 }}</h3>
                  <p>{{ $t("contacts") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-envelope"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
        </div>
        <!-- /.row -->
        <div class="row">
          <!-------------------------------->
          <!------ Products chart ----------->
          <!-------------------------------->
          <div class="col-md-12 mb-3 mt-2">
            <b-card no-body>
              <b-card-header
                ><b>{{ $t("best_sellers_products") }}</b></b-card-header
              >
              <b-card-body class="p-0">
                <i
                  class="fa fa-sync  fa-spin load-con"
                  v-if="!enable_products_chart"
                ></i>

                <apexchart
                  :height="400"
                  v-if="enable_products_chart"
                  type="bar"
                  :options="products_chart"
                  :series="products_chart.series"
                  style="margin-top:-20px"
                ></apexchart>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>

      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      load: false,
      dashboard: {},
      enable_products_chart: true,
      products_chart: {
        series: [
          {
            name: "",
            data: []
          }
        ],
        colors: ["#EB1B4D", "#73A6FF", "#757575", "#FFDC51"],
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          type: "bar"
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function(value, { w }) {
              return (
                "(" +
                value +
                ")" +
                (
                  (value /
                    w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0)) *
                  100
                ).toFixed(1) +
                "%"
              );
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            colors: ["#304758"]
          },
          formatter: (value, { w }) => {
            return (
              "(" +
              value +
              ")" +
              (
                (value /
                  w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0)) *
                100
              ).toFixed(1) +
              "%"
            );
          }
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#008FFB",
              fontFamily: "cairo"
            }
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "#008FFB"
            },
            labels: {
              style: {
                colors: "#008FFB"
              }
            }
          }
        ],
        legend: {
          show: true,
          markers: {
            width: 12,
            height: 12,
            radius: 0
          }
        }
      }
    };
  },
  created() {
    this.getDashboard();
  },
  methods: {
    getDashboard() {
      this.axios
        .get("dashboard")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.dashboard = res.result.dashboard;
            this.best_sellers_products =
              res.result.dashboard.best_sellers_products;

            let data = this.best_sellers_products.map(
              e => e["name_" + this.$i18n.locale]
            );
            let data_total = this.best_sellers_products.map(e => e.sales_count);
            this.$set(this.products_chart.series[0], "data", data_total);
            this.$set(this.products_chart.xaxis, "categories", data);

            this.enable_products_chart = false;
            this.$nextTick(() => {
              this.enable_products_chart = true;
            });
          } else {
            this.handleError("getDashboard", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getDashboard", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    copyThis(text) {
      this.$copyText(text).then(
        () => {
          this.$noty.success(this.$t("copied"));
        },
        function() {
          this.$noty.warning(this.$t("error"));
        }
      );
    }
  }
};
</script>
