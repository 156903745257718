<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("products") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("products") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div
                          class="input-group input-group-sm"
                          style="width: 150px;"
                        >
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(products, 'id')">
                              #
                            </th>
                            <th>{{ $t("image") }}</th>
                            <th
                              class="sort"
                              @click="
                                kimoSort(products, 'name_' + $i18n.locale)
                              "
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(products, 'category_id')"
                            >
                              {{ $t("category") }}
                            </th>

                            <th
                              v-if="false"
                              class="sort"
                              @click="kimoSort(products, 'qty')"
                            >
                              {{ $t("quantity") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(products, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(products, 'in_stock')"
                            >
                              {{ $t("in_stock") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(products, 'status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="9" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="9"
                              class="no_data"
                              v-if="load && products.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in products"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>
                            <td>
                              <img
                                :src="image_url + '/' + item.image"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td>{{ item["name_" + $i18n.locale] }}</td>
                            <td>
                              {{
                                (item.category || {})["name_" + $i18n.locale]
                              }}
                            </td>

                            <td v-if="false">{{ item.qty }}</td>

                            <td>
                              <span
                                v-tooltip="
                                  $moment(item.created_at)
                                    .tz($store.state.timezone)
                                    .format('Y-MM-DD hh:mm A')
                                "
                              >
                                {{
                                  $moment(item.created_at)
                                    .tz($store.state.timezone)
                                    .format("Y-MM-DD")
                                }}
                              </span>
                            </td>
                            <td class="pb-0">
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit products')"
                                  v-model="item.in_stock"
                                  :true-value="'1'"
                                  :false-value="'0'"
                                  @change="
                                    $can('edit products')
                                      ? changeInStock(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round y_n_switch">
                                  <span class="on">{{ $t("yes") }}</span>
                                  <span class="off">{{ $t("no") }}</span>
                                </div>
                              </label>
                            </td>
                            <td class="pb-0">
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit products')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit products')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td class="actions-group">
                              <router-link
                                v-if="$can('edit products')"
                                :to="'/products/' + item.id"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </router-link>
                              <button
                                v-if="$can('delete products')"
                                @click="deleteProduct(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add products')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div>
                          <b-tabs content-class="mt-3" no-key-nav>
                            <b-tab
                              :title="$t('info')"
                              active
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'image_input',
                                    'name_ar',
                                    'name_en',
                                    'desc_ar',
                                    'desc_en',
                                    'brand_id',
                                    'category_id',
                                    'size_id',
                                    'sku',
                                    'qty',
                                    'price',
                                    'prepared_time'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("image") }}</label>

                                  <div class="image_div" v-if="add_data_image">
                                    <img :src="add_data_image" />
                                    <i
                                      class="fa fa-times"
                                      @click.prevent="
                                        add_data_image = null;
                                        add_data_icon = null;
                                      "
                                    ></i>
                                  </div>
                                  <b-form-file
                                    v-else
                                    :placeholder="$t('choose_file')"
                                    accept="image/*"
                                    @change.prevent="
                                      onImageChange($event, 'add_data')
                                    "
                                    ref="add_data_icon"
                                  ></b-form-file>
                                  <span
                                    class="error invalid-feedback d-block"
                                    >{{
                                      (add_errors.image_input || []).join("-")
                                    }}</span
                                  >
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("name") }} | {{ $t("ar") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.name_ar
                                    }"
                                    v-model="add_data.name_ar"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.name_ar || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("name") }} | {{ $t("en") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.name_en
                                    }"
                                    v-model="add_data.name_en"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.name_en || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("desc") }} | {{ $t("ar") }}</label
                                  >
                                  <textarea
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.desc_ar
                                    }"
                                    rows="4"
                                    v-model="add_data.desc_ar"
                                  ></textarea>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.desc_ar || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("desc") }} | {{ $t("en") }}</label
                                  >
                                  <textarea
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.desc_en
                                    }"
                                    rows="4"
                                    v-model="add_data.desc_en"
                                  ></textarea>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.desc_en || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("category") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.category_id
                                    }"
                                    v-model="add_data.category_id"
                                  >
                                    <option selected="" disabled="" value="">{{
                                      $t("select")
                                    }}</option>
                                    <option
                                      v-for="(cat, i) in categories"
                                      :key="i"
                                      :value="cat.id"
                                      >{{ cat["name_" + $i18n.locale] }}</option
                                    >
                                  </select>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.category_id || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("brand") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.brand_id
                                    }"
                                    v-model="add_data.brand_id"
                                  >
                                    <option selected="" disabled="" value="">{{
                                      $t("select")
                                    }}</option>
                                    <option
                                      v-for="(brand, i) in brands"
                                      :key="i"
                                      :value="brand.id"
                                      >{{
                                        brand["name_" + $i18n.locale]
                                      }}</option
                                    >
                                  </select>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.brand_id || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("sku") }}</label>
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    :class="{ 'is-invalid': add_errors.sku }"
                                    v-model="add_data.sku"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.sku || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("prepared_time") }} ({{
                                      $t("day")
                                    }})</label
                                  >
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.prepared_time
                                    }"
                                    v-model="add_data.prepared_time"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.prepared_time || []).join("-")
                                  }}</span>
                                </div>
                              </div>
                            </b-tab>
                            <b-tab
                              :title="$t('sizes')"
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row col-md-12">
                                <div
                                  class="col-md-12"
                                  v-for="(add_size, m) in add_data.sizes"
                                  :key="m"
                                >
                                  <div class="input-group mb-3">
                                    <select
                                      class="form-control pt-0"
                                      v-model="add_data.sizes[m].size_id"
                                      :class="{
                                        'is-invalid':
                                          add_errors['sizes.' + m + '.size_id']
                                      }"
                                    >
                                      <option disabled value="">{{
                                        $t("size")
                                      }}</option>
                                      <option
                                        :value="size.id"
                                        v-for="(size, i) in sizes"
                                        :key="i"
                                        >{{ size.name }}</option
                                      >
                                    </select>
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="add_data.sizes[m].price"
                                      class="form-control ltr"
                                      :class="{
                                        'is-invalid':
                                          add_errors['sizes.' + m + '.price']
                                      }"
                                      :placeholder="$t('price')"
                                    />
                                    <input
                                      type="number"
                                      min="0"
                                      class="form-control ltr"
                                      :class="{
                                        'is-invalid':
                                          add_errors['sizes.' + m + '.qty']
                                      }"
                                      v-model="add_data.sizes[m].qty"
                                      :placeholder="$t('qty')"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-info"
                                        @click="addNewSize"
                                        type="button"
                                        v-tooltip="$t('add_new_size')"
                                      >
                                        <i class="fa fa-plus"></i>
                                      </button>
                                    </div>
                                    <div class="input-group-append">
                                      <button
                                        v-if="add_data.sizes.length != 1"
                                        class="btn btn-danger"
                                        @click="deleteSize(m)"
                                        type="button"
                                        v-tooltip="$t('delete_size')"
                                      >
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                    <span class="error invalid-feedback">{{
                                      (
                                        add_errors["sizes." + m + ".size_id"] ||
                                        []
                                      ).join("-")
                                    }}</span>
                                    <span class="error invalid-feedback">{{
                                      (
                                        add_errors["sizes." + m + ".price"] ||
                                        []
                                      ).join("-")
                                    }}</span>
                                    <span class="error invalid-feedback">{{
                                      (
                                        add_errors["sizes." + m + ".qty"] || []
                                      ).join("-")
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </b-tab>
                            <b-tab
                              :title="$t('discount')"
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'discount_type',
                                    'discount_value',
                                    'discount_start',
                                    'discount_end'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row col-md-12">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("discount_type") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.discount_type
                                    }"
                                    v-model="add_data.discount_type"
                                  >
                                    <option value="percentage">{{
                                      $t("percentage")
                                    }}</option>
                                    <option value="fixed">{{
                                      $t("fixed_value")
                                    }}</option>
                                  </select>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_type || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-12">
                                  <label>{{ $t("discount_value") }}</label>
                                  <input
                                    type="number"
                                    min="0"
                                    :max="
                                      add_data.discount_type == 'percentage'
                                        ? 99
                                        : add_data.price
                                    "
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.discount_value
                                    }"
                                    v-model="add_data.discount_value"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_value || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("discount_start") }}</label>
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.discount_start
                                    }"
                                    v-model="add_data.discount_start"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_start || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("discount_end") }}</label>
                                  <input
                                    type="datetime-local"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.discount_end
                                    }"
                                    v-model="add_data.discount_end"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_end || []).join("-")
                                  }}</span>
                                </div>
                              </div>
                            </b-tab>

                            <b-tab
                              :title="$t('images')"
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  ['images_input'].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row col-md-12">
                                <div class="form-row">
                                  <div class="form-group new-input col-md-12">
                                    <label>{{ $t("images") }}</label>
                                    <b-form-file
                                      @change.prevent="upImages"
                                      multiple
                                      v-model="images_input"
                                      :file-name-formatter="formatNames"
                                      accept="image/*"
                                    ></b-form-file>
                                  </div>

                                  <div class="col-md-12">
                                    <div
                                      class="img_div"
                                      v-for="(img, i) in images_arr"
                                      :key="i"
                                    >
                                      <img :src="img.image" />
                                      <div
                                        @click.prevent="removeImg(i)"
                                        v-if="!disabled"
                                      >
                                        <i class="fa fa-times"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addProduct"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Products",
  data() {
    return {
      load: false,
      products: [],
      search_key: "",
      old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data: {
        brand_id: "",
        category_id: "",
        sizes: [
          {
            size_id: "",
            price: "",
            qty: ""
          }
        ],
        discount_type: "percentage"
      },
      image_url: "",
      categories: [],
      sub_categories: [],
      brands: [],
      sizes: [],
      search_disabled: false,
      attribute_sets: [],
      attributes: [],
      images_input: [],
      images_arr: [],
      disabled: false
    };
  },
  created() {
    this.getProducts();
  },
  watch: {
    currentPage() {
      this.getProducts();
    }
  },
  methods: {
    getProducts() {
      return this.axios
        .get("products", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.brands = res.result.brands;
            this.categories = res.result.categories;
            this.sizes = res.result.sizes;
            this.remaining_sizes = res.result.sizes;
            this.products = res.result.products.data;
            this.rows = res.result.products.total;
            this.perPage = res.result.products.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getProducts", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getProducts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    getCategoryAttributes() {
      this.axios
        .post("products/category_attributes/" + this.add_data.category_id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.attributes = res.result.attributes;
          } else {
            this.alert(res.message);
          }
        })
        .catch(error => {
          this.handleError("getCategoryAttributes", error);
        });
    },
    addProduct() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          if (key == "sizes") {
            this.add_data.sizes.forEach((e, k) => {
              fd.append("sizes[" + k + "][size_id]", e.size_id);
              fd.append("sizes[" + k + "][price]", e.price);
              fd.append("sizes[" + k + "][qty]", e.qty);
            });
            return true;
          }
          fd.append(key, this.add_data[key]);
        }
      });

      this.images_input.forEach((e, i) => {
        if (this.isImage(e)) {
          fd.append("images_input[" + i + "]", e, e.name);
        }
      });

      this.add_error = this.add_success = "";
      this.axios
        .post("products/add", fd)
        .then(response => {
          let res = response.data;
          this.add_errors = {};
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getProducts();
            this.add_data = {
              category_id: "",
              brand_id: "",
              sizes: [
                {
                  size_id: "",
                  price: "",
                  qty: ""
                }
              ],
              discount_type: "percentage"
            };
            this.add_data_image = null;
            this.add_data_icon = null;
            this.images_input = [];
            this.images_arr = [];
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addProduct", res.message);
          }
        })
        .catch(error => {
          this.handleError("addProduct", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.products, "id", id);
      let i = this.findIndex(this.products, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("products/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.products[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    changeInStock(id) {
      let index = this.findIndex(this.products, "id", id);
      let i = this.findIndex(this.products, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("products/update_in_stock/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.products[index].in_stock = res.result.in_stock;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeInStock", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeInStock", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteProduct(id) {
      let index = this.findIndex(this.products, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("products/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.products.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteProduct", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteProduct", error);
              });
          }
        });
    },
    updatePosition(event) {
      this.$set(this.add_data, "lat", event.latLng.lat());
      this.$set(this.add_data, "lng", event.latLng.lng());
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
    },
    upImages(evt) {
      evt.target.files.forEach(e => {
        if (this.isImage(e)) {
          this.toBase64(e).then(res => {
            this.images_arr.push({ name: e.name, image: res });
          });
        }
      });
    },
    removeImg(i) {
      this.images_input.splice(i, 1);
      this.images_arr.splice(i, 1);
    },
    addNewSize() {
      if (this.add_data.sizes[this.add_data.sizes.length - 1].size_id) {
        this.add_data.sizes.push({
          size_id: "",
          price: "",
          qty: ""
        });
      }
    },
    deleteSize(m) {
      this.add_data.sizes.splice(m, 1);
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
