<template>
  <div>
    <b-modal
      ref="singal_modal"
      :title="'#' + reservation.id"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block" v-if="reservation.id">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <th style="width:50%" class="border-top-0">#</th>
                <td class="border-top-0">
                  {{ isAdmin ? reservation.r_id : reservation.id }}
                </td>
              </tr>
              <tr v-if="!isUser">
                <th>{{ $t("user") }}</th>
                <td>{{ (reservation.user || {})["name_" + $i18n.locale] }}</td>
              </tr>
              <tr v-if="!isUser">
                <th>{{ $t("phone") }}</th>
                <td>{{ (reservation.user || {}).phone || "-" }}</td>
              </tr>
              <tr v-if="!isAdvertiser">
                <th>{{ $t("advertiser_2") }}</th>
                <td>
                  {{ (reservation.advertiser || {})["name_" + $i18n.locale] }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("created_at_r") }}</th>
                <td>{{ reservation.r_time }}</td>
              </tr>
              <tr>
                <th>{{ $t("r_type") }}</th>
                <td>{{ $t(reservation.r_type) || "-" }}</td>
              </tr>
              <tr>
                <th>{{ $t("notes") }}</th>
                <td>{{ reservation.notes || "-" }}</td>
              </tr>
              <tr>
                <th>{{ $t("created_at") }}</th>
                <td>
                  {{
                    $moment(reservation.created_at)
                      .tz($store.state.timezone)
                      .format("YY-MM-DD hh:mm A")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ $t("reservations") }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">{{ $t("home") }}</router-link>
              </li>
              <li class="breadcrumb-item active">{{ $t("reservations") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchAll"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="sort" @click="kimoSort(reservations, 'id')">
                        #
                      </th>
                      <th
                        v-if="!isUser"
                        class="sort"
                        @click="kimoSort(reservations, 'user_id')"
                      >
                        {{ $t("user") }}
                      </th>
                      <th
                        v-if="!isAdvertiser"
                        class="sort"
                        @click="kimoSort(reservations, 'advertiser_id')"
                      >
                        {{ $t("advertiser_2") }}
                      </th>
                      <th
                        class="sort"
                        @click="kimoSort(reservations, 'created_at')"
                      >
                        {{ $t("created_at") }}
                      </th>
                      <th
                        class="sort"
                        @click="kimoSort(reservations, 'status')"
                      >
                        {{ $t("status") }}
                      </th>
                      <th>{{ $t("actions") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" v-if="!load">
                        <i class="fa fa-sync  fa-spin load-con"></i>
                      </td>
                      <td
                        colspan="8"
                        class="no_data"
                        v-if="load && reservations.length == 0"
                      >
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item, i) in reservations"
                      :key="i"
                      v-show="load"
                    >
                      <td>{{ item.id }}</td>

                      <td v-if="!isUser">
                        {{ (item.user || {})["name_" + $i18n.locale] || "-" }}
                      </td>
                      <td v-if="!isAdvertiser">
                        <router-link
                          v-if="isAdmin"
                          :to="'/advertisers/' + (item.advertiser || {}).id"
                          >{{
                            (item.advertiser || {})["name_" + $i18n.locale] ||
                              "-"
                          }}</router-link
                        >
                        <span v-if="isUser">{{
                          (item.advertiser || {})["name_" + $i18n.locale] || "-"
                        }}</span>
                      </td>

                      <td>
                        {{
                          $moment(item.created_at)
                            .tz($store.state.timezone)
                            .format("YY-MM-DD hh:mm A")
                        }}
                      </td>
                      <td>
                        <span
                          :class="
                            'badge badge-' +
                              (item.status == 'accepted' ? 'info' : 'danger')
                          "
                          >{{ item.status || "-" }}</span
                        >
                      </td>
                      <td class="actions-group">
                        <button
                          @click="showReservation(item.id)"
                          class="btn btn-danger btn-sm"
                          v-tooltip="$t('show')"
                        >
                          <i class="fas fa-eye"> </i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Reservations",
  data() {
    return {
      load: false,
      reservations: [],
      reservation: {},
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1
    };
  },
  created() {
    this.getReservation();
  },
  watch: {
    currentPage() {
      this.getReservation();
    }
  },
  methods: {
    getReservation() {
      return this.axios
        .get("reservations", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.reservations = res.result.reservations.data;
            this.rows = res.result.reservations.total;
            this.perPage = res.result.reservations.per_page;
          }
        })
        .catch(error => {
          this.handleError("getReservation", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getReservation().then(() => {
          this.search_disabled = false;
        });
      }
    },
    showReservation(id) {
      this.reservation = this.reservations.find(e => e.id == id);
      this.$refs.singal_modal.show();
    }
  }
};
</script>
